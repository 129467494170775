.news-one-lg {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;


  .desktop &.loaded:hover {
    .news-one-lg__bg {
      .webp-img {
        opacity: 1;
        visibility: visible;
      }
      &:after {
        width: 100%;
        transition-duration:$anim * 3;
      }
    }
  }
  .desktop &:hover {
    .news-one-lg__bg {
      .news-one-lg__img {
        transform: scale(1.05);
        transition-duration:$anim * 3;
      }
      &:after {
        width: 100%;
        transition-duration:$anim * 3;
      }
    }
    .bar-block {
      opacity: 1;
      visibility: visible;
    }

    .nol__btn {
      color: $main-black;
      background: $color-main;

      &:before {
        border-color: $color-main;
      }

      .icon {
        fill: $main-black;
      }
    }
  }
  &.loaded {
    .bar-block {
      display: none;
    }
  }
  .news-one-lg__bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    .webp-img {
      display: block;
      min-width: 100%;
      min-height: 100%;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate3d(-50%,-50%,0);
      opacity: 0;
      transition: all $anim*2 ease;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background: $color-main;
      transition: width $anim ease;
    }

    .news-one-lg__img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      transition: transform $anim ease;
    }
  }
  .news-one-lg__inner {
    display: flex;
    flex-direction: row;
    min-height: 590px;
    height: 100%;
    align-items: flex-end;
    position: relative;
    padding: 60px;
  }
  .nol__content {
    display: block;
    width: calc(50% - 15px);
  }
  .nol__info {
    display: block;
    width: 100%;
    background: url(../images/bg-lines.png) 0 0 repeat;
    padding: 7px 12px;
    color: $color-main;
    margin: 0 0 15px;
  }
  .nol__title {
    color: $white;
    margin: 0 0 30px;
  }
  .nol__more {
    display: flex;
    flex-direction: row;
  }
  .nol__btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: .43px;
    color: $white;
    font-weight: 500;
    padding: 16px 20px;
    text-transform: uppercase;
    width: auto;
    position: relative;
    transition: color $anim ease, background-color $anim ease;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid rgba($white, .5);
      transition: border-color $anim ease;
    }

    .icon {
      width: 8px;
      height: 8px;
      fill: $white;
      margin-left: 18px;
      transition: fill $anim ease;
      position: relative;
      overflow: visible;
    }
  }
  @include r($xl) {
    .news-one-lg__inner {
      padding: 40px;
      min-height: 59.3333333%;
    }
    .nol__info {
      padding: 6px 10px;
    }
    .nol__btn {
      padding: 13px 20px;
    }
  }
  @include r($lg) {
    .news-one-lg__inner {
      height: calc((100vw - 60px) * 0.5929577464788732);
    }
    .nol__content {
      width: 55%;
      max-width: 360px;
    }
  }
  @include r($sm) {
    margin-left: -20px;
    margin-right: -20px;
    width: auto;
    .news-one-lg__inner {
      padding: 30px 20px;
    }
    .nol__content {
      width: 100%;
    }
    .news-one-lg__inner {
      height: calc(100vw * 0.8333333333);
      min-height: 400px;
    }
  }//$sm
}//news-one-lg

.news-one-sm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  &:before {
    background-image: linear-gradient(to right, rgba($grey, .3) 33.333%, rgba(255, 255, 255, 0) 0%);
    background-position: top left;
    background-size: 6px 2px;
    background-repeat: repeat-x;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: $color-main;
    transition: width $anim ease;
  }
  .news-one__info {
    padding: 7px 0;
  }

  &.loaded {
    .news-one__inner {
      .bar-block {
        display: none;
      }
    }
  }

  .desktop &.loaded:hover {
    .news-one__img-wrap {
      .webp-img {
        opacity: 1;
        visibility: visible;
      }
    }
    &.news-one-sm-video {
      &:after {
        width: 100%;
        transition-duration:$anim * 3;
      }
    }
  }
  .desktop &:hover {
    &.news-one-sm-video {
      .news-one__inner {
        &:before {
          height: 0;
        }
      }
      .news-one__info {
        color: $grey;
      }
      .news-one__title {
        color: $white;
      }
    }
    .news-one__img-wrap {
      .news-one__img {
        transform: scale(1.05);
        transition-duration: $anim * 3;
      }
      .webp-img {
        &.loaded {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .news-one__inner {
      &:before {
        height: calc(100% + 80px);
        transition-duration: $anim * 2;
      }
      .bar-block {
        opacity: 1;
        visibility: visible;
      }
    }
    .news-one__info,
    .news-one__title {
      color: $main-black;
    }
  }

  &.news-one-sm-video {
    .news-one__img-wrap {
      height: auto;
      padding: 0 0 56.25%;
    }
  }
  .news-one__img-wrap {
    display: block;
    width: 100%;
    height: 416px;
    margin: 0;
    overflow: hidden;
    position: relative;
    z-index: 5;

    .webp-img {
      display: block;
      min-width: 100%;
      min-height: 100%;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate3d(-50%,-50%,0);
      opacity: 0;
      transition: all $anim*2 ease;
    }
    .news-one__img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: 50%;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      transition: transform $anim ease;
    }
  }
  .video-icon {
    display: block;
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 22px;
    height: 22px;

    .icon {
      fill: $white;
    }
  }
  .news-one__inner {
    display: block;
    padding-bottom: 30px;
    padding-right: 40px;
    position: relative;
    padding-top: 20px;
    flex: 1 0 auto;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -80px;
      left: -20px;
      right: -20px;
      height: 0;
      background: $color-main;
      transition: all $anim ease;
    }
  }
  .news-one__info {
    display: block;
    width: 100%;
    color: $grey;
    margin: 0 0 10px;
    transition: color $anim ease;
    position: relative;
  }
  .news-one__title {
    color: $white;
    width: 80%;
    max-width: 480px;
    transition: color $anim ease;
    position: relative;
    margin: 0 0 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  @include r($xl) {
    .news-one__title {
      margin-bottom: 32px;
    }
    .news-one__inner {
      &:before {
        left: -15px;
        right: -15px;
      }
    }
    .news-one__info {
      padding: 4px 0;
      margin-bottom: 6px;
    }
  }
  @include r($xl) {
    &.news-one-sm-video {
      .news-one__img-wrap {
        width: 100%;
        padding: 0 0 56.25%;
        height: auto;
      }
    }
    .news-one__img-wrap {
      width: 100%;
      padding: 0 0 83.333333%;
      height: auto;
    }
  }
  @include r($lg) {
    .news-one__title {
      width: 75%;
      max-width: 360px;
    }
  }
  @include r($sm) {
    .news-one__title {
      width: 80%;
      min-width: 280px;
    }
  }
}//news-one-sm

.col-md-12 {
  .news-one-sm {
    .news-one__img-wrap {
      @include r($xl) {
        padding: 0 0 calc(41.66665% - 12.5px);
      }
      @include r($sm) {
        width: 100%;
        padding: 0 0 83.333333%;
        height: auto;
      }
    }
  }
}

.news-one-fl {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: relative;
    height: 416px;
  }

  .desktop &:hover {
    .news-one__img-wrap {
      &:after {
        width: 100%;
        transition-duration:$anim * 3;
      }
      .news-one__img {
        transform: scale(1.05);
        transition-duration: $anim * 3;
      }
    }
  }

  .news-one__img-wrap {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    overflow: hidden;
    position: absolute;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background: $color-main;
      transition: width $anim ease;
    }

    .news-one__img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: 50%;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      transition: transform $anim ease;
    }
  }
  .news-one__inner {
    display: block;
    padding-left: 80px;
    padding-right: 80px;
    text-align: center;
    position: relative;
    padding-top: 20px;
    padding-bottom: 10px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -80px;
      left: -20px;
      right: -20px;
      height: 0;
      background: $color-main;
      transition: all $anim ease;
    }
  }
  .news-one__info {
    display: block;
    width: 100%;
    background: url(../images/bg-lines.png) 0 0 repeat;
    padding: 7px 12px;
    color: $color-main;
    margin: 0 0 10px;
    text-align: center;
  }
  .news-one__title {
    color: $white;
    max-width: 100%;
    transition: color $anim ease;
    position: relative;
  }

  @include r($xl) {
    &:before {
      width: 100%;
      padding: 0 0 83.3333333%;
      height: auto;
    }
    .news-one__inner {
      padding-left: 0;
      padding-right: 0;
      max-width: 80%;
      margin: 0 auto;
      &:before {
        left: -15px;
        right: -15px;
      }
    }
    .news-one__info {
      padding: 4px 10px;
      margin-bottom: 6px;
    }
  }
  @include r($lg) {
    .news-one__inner {
      width: 100%;
      max-width: 424px;
      padding-left: 32px;
      padding-right: 32px;
    }
  }
  @include r($sm) {
    .news-one__inner {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}//news-one-fl


.video-controls {
  display: block;
  position: relative;
  min-height: 56px;
  padding-left: 56px + 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .video-icon {
    display: block;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 1px solid rgba($grey, .5);
    position: absolute;
    left: 0;
    top: 0;
    transition: background-color $anim ease, border-color $anim ease;

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -9px;
      margin-left: -3px;
      border-left: 13px solid rgba($grey, .5);
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      transition: border-color $anim ease;
    }
  }
  .video-text {
    display: block;
    color: rgba($grey, .5);
    transition: color $anim ease;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-transform: uppercase;
  }
  .video-time {
    display: block;
    color: rgba($grey, .5);
    transition: color $anim ease;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.5px;
  }
  .desktop .news-one-lg:hover &,
  .desktop .news-one-sm:hover & {
    .video-icon {
      background: $color-main;
      border-color: $color-main;

      &:after {
        border-left-color: $main-black;
      }
    }
    .video-text,
    .video-time {
      color: $white;
    }
  }
  @include r($xl) {
    min-height: 50px;
    padding-left: 65px;
    .video-icon {
      width: 50px;
      height: 50px;
      &:after {
        margin-top: -8px;
        margin-left: -4px;
        border-left: 11.6px solid rgba($grey, .5);
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
      }
    }
    .video-text {
      font-size: 16px;
    }
  }
  @include r($lg) {
    display: none;
    .news-one-lg & {
      display: flex;
    }
  }
  @include r($sm) {
    display: flex;
    min-height: 30px;
    padding-left: 40px;
    .video-icon {
      width: 30px;
      height: 30px;
      &:after {
        margin-top: -5px;
        margin-left: -2.5px;
        border-left: 7.4px solid rgba($grey, .5);
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
      }
    }
    .video-text,
    .video-time {
      font-size: 10px;
      letter-spacing: .42px;
      line-height: 1.5;
    }
  }
}//video-controls

.slide-one-lg {
  .video-controls {
    .video-icon {
      border-color: rgba($white, .5);
      &:after {
        border-left-color: $white;
      }
    }
    .video-text,
    .video-time {
      color: $white;
    }
  }
}

.device {
  .video-controls {
    .video-icon {
      background: $color-main;
      border-color: $color-main;
      &:after {
        border-left-color: $main-black;
      }
    }
    .video-text,
    .video-time {
      color: $white;
    }
  }
}
