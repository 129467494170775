.section__utp-wrapper {
  @include r($md) {
    position:relative;
    overflow:auto;
  }
}
.section__utp {
  align-items:flex-end;
  min-height:300px;
  min-width:1004px;
  position:relative;
  &__item {
    height:100%;
    display:flex;
    align-items:flex-start;
    justify-content:flex-end;
    &-card {
      padding-bottom:10px;
      position:relative;
      &:before {
        content: '';
        background-image: linear-gradient(to right, rgba($grey, .5) 33.333%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom left;
        background-size: 6px 2px;
        background-repeat: repeat-x;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
      }
      &:after {
        content:'';
        position:absolute;
        bottom:0px;
        left:0;
        width:0;
        height:3px;
        background-color:$color-main;
        transition:width 0.25s ease-in-out;
      }
      &__title {
        margin-bottom:20px;
        max-width:60%;
        h3 {
          font-size: 20px;
          transition:all 0.25s ease-in-out;
          transform-origin:left bottom;
          line-height:1.33;
        }
      }
      &__dscr {
        color: $base-color;
        transition:opacity 0.25s ease-in-out;
        font-size:18px;
        line-height:28px;
        letter-spacing: .5;
        opacity:0;
        span {
          display:block;
          transition:height 0.25s ease-in-out;
          height:0;
          position:relative;
          overflow:hidden;
        }
      }
    }
  }
  @include r($xl) {
    min-height:270px;
    &__item {
      &-card {
        &__title {
          margin-bottom:20px;
          h3 {
            font-size:18px;
          }
        }
        &__dscr {
          font-size:16px;
          line-height:24px;
        }
      }
    }
  }
  @include r(1365px) {
    min-height:200px;
    &__item {
      &-card {
        &__title {
          margin-bottom:15px;
          max-width:66.6666%;
          h3 {
            font-size:16px;
          }
        }
        &__dscr {
          font-size:14px;
          line-height:20px;
          letter-spacing:0.5px;
        }
      }
    }
  }
  @include r($lg) {
    min-height:220px;
  }
  @include r($md) {
    min-height:214px;
    &__item {
      width:251px;
      max-width:none;
      &-card {
        &__title {
          max-width:66.6666%;
          h3 {
            font-size:14px;
          }
        }
        &__dscr {
          font-size:12px;
          line-height:16px;
        }
      }
    }
  }
  @include r($sm) {
    min-height:190px;
  }
}
.html--desktop {
  .section__utp {
    &__item {
      &.appear,
      &:hover {
        .section__utp__item-card {
          &:after {
            width:100%;
          }
          .section__utp__item-card__title {
            h3 {
              transform:scale(1.66666667);
            }
          }
          .section__utp__item-card__dscr {
            opacity:1;
            span {
              height:140px;
            }
          }
        }
      }
    }
    @include r($xl) {
      &__item {
        &.appear,
        &:hover {
          .section__utp__item-card {
            .section__utp__item-card__dscr {
              span {
                height:120px;
              }
            }
          }
        }
      }
    }
    @include r(1365px) {
      &__item {
        &.appear,
        &:hover {
          .section__utp__item-card {
            .section__utp__item-card__title {
              h3 {
                transform:scale(1.5);
              }
            }
            .section__utp__item-card__dscr {
              span {
                height:100px;
              }
            }
          }
        }
      }
    }
    @include r($md) {
      &__item {
        &.appear,
        &:hover {
          .section__utp__item-card {
            .section__utp__item-card__dscr {
              span {
                height:80px;
              }
            }
          }
        }
      }
    }
  }
}
.html--device {
  .section__utp {
    &__item {
      &.active {
        .section__utp__item-card {
          &:after {
            width:100%;
          }
          .section__utp__item-card__title {
            h3 {
              transform:scale(1.5);
            }
          }
          .section__utp__item-card__dscr {
            opacity:1;
            span {
              height:120px;
            }
          }
        }
      }
    }
    @include r(1365px) {
      &__item {
        &.active {
          .section__utp__item-card {
            .section__utp__item-card__title {
              h3 {
                transform:scale(1.5);
              }
            }
            .section__utp__item-card__dscr {
              span {
                height:100px;
              }
            }
          }
        }
      }
    }
    @include r($md) {
      &__item {
        &.active {
          .section__utp__item-card {
            .section__utp__item-card__dscr {
              span {
                height:80px;
              }
            }
          }
        }
      }
    }
  }
}
