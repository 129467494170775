.table-partners {
  display: block;
  width: calc(100% + 1px);
  margin: 0 0 60px;

  .table-partners__inner {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    flex-wrap: wrap;
    width: 100%;
  }
  .table-partners__cell {
    display: block;
    flex-basis: 25%;
    flex-grow: 10;
    padding: 0 1px 1px 0;
  }
  .cell-inside {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 40px 15px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $dark-grey;
      opacity: .15;
    }
    .desktop & {
      &:before {
        transition: opacity $anim ease-in-out;
      }
      &:hover {
        &:before {
          opacity: .25;
        }
      }
    }
  }
  .cell-img {
    display: block;
    width: 100%;
    max-width: 100px;
    margin: 0;
    position: relative;
  }
  @include r($xl) {
    margin-bottom: 40px;
  }
  @include r($sm) {
    .table-partners__cell {
      flex-basis: 50%;
    }
  }
}//table-partners
