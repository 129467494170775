
.news-list {
  display: block;
  width: 100%;
}
.news-one {
  display: block;
  height: 100%;
  padding-bottom: 40px;
  @include r($sm) {
    padding-bottom: 30px;
  }

  .slide-one & {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.animated-adding {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
    animation: animating $anim*2 forwards;
  }
  @keyframes animating {
    0% {
      opacity: 0;
      transform: translate3d(0, 40px, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}
.nav-item__block {
  display: block;
  height: 0;
  width: 100%;
  position: static;

  @include r($sm) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    order: 1;
    height: auto;
    min-height: 70px;
    background: url(../images/bg-lines-seamless.png) 0 0 repeat;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #796E44;
      opacity: .7;
    }
  }
}
.nav-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  padding: 20px 30px;
  color: $white;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: .5px;
  font-weight: 500;
  transition: color $anim ease;
  flex: 0 1 40%;

  &.nav-item-next {
    justify-content: flex-end;
    right: 0;
    left: auto;
    text-align: right
  }
  .nav-item__icon {
    display: block;
    width: 14px;
    min-width: 14px;
    height: 14px;
    fill: $white;

    &:first-child {
      margin-right: 20px;
    }
    &:last-child {
      margin-left: 20px;
    }
  }
  .desktop & {
    transition: all $anim ease-in-out;
    .nav-item__icon {
      transition: all $anim ease-in-out;
    }
    &:hover {
      color: $color-main;
      .nav-item__icon {
        fill: $color-main;
      }
    }
  }

  @include r($xl) {
    font-size: 16px;
    line-height: 26px;
    padding: 15px 20px;
  }
  @include r($md) {
    flex: 0 1 35%;
  }
  @include r($sm) {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    order: 1;
    width: 50%;
    left: auto;
    top: auto;
    margin: 0;
    height: auto;
    max-width: 50%;
    flex: 0 1 50%;
    min-height: 70px;
    transform: none;
    font-size: 12px;
    line-height: 18px;
    padding: 15px 20px;

    .nav-item__icon {
      display: none;
    }
    &.nav-item-prev {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -.5px;
        width: 1px;
        background: rgba(#796e44, .7);
    }
    }
    &.nav-item-next {
      .nav-item__name {
        margin-left: auto;
      }
    }
  }
}//nav-item

.banner-block {
  display: block;
  position: relative;
  width: 100%;

  .banner-block__bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }

  .banner-block__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 650px;
    padding: 0 20px;
    position: relative;
  }
  @include r($xl) {
    .banner-block__inner {
      min-height: 500px;
    }
  }
  @include r($md) {
    .banner-block__inner {
      min-height: 480px;
    }
  }
}

.news-slider__wrap {
  display: block;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
  margin-bottom: 55px;
  margin-top: 80px;

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 60px;
    left: 0;
    width: calc(50vw - 565px);
    z-index: 10;
  }

  &:before {
    @include gradiendHorizontal(rgba(19,20,20,1), rgba(19,20,20,0),0%, 100%)
  }
  &:after {
    left: auto;
    right: 0;
    @include gradiendHorizontal(rgba(19,20,20,0), rgba(19,20,20,1),0%, 100%)
  }

  .news-slider {
    display: block;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  .slick-list {
    overflow: visible;
  }
  .slide-one {
    display: block;
    width: 100%;
    height: 100%;
    opacity: .2;
    padding: 0 20px;
    transition: opacity $anim ease-in-out;
    &.slick-current {
      opacity: 1;
    }

    img {
      display: block;
      width: 100%;
    }
  }
  .slick-dots {
    bottom: -52px;
  }
  .slick-arrow {
    left: 50%;
    &.slick-prev {
      margin-left: -50vw;
      transform: translate(8vw, 0);
    }
    &.slick-next {
      left: auto;
      right: 50%;
      margin-right: -50vw;
      transform: translate(-8vw, 0);
    }
  }
  @include r($xl) {
    margin-bottom: 50px;
    .slick-dots {
      bottom: -42px;
    }
  }
  @include r($lg) {
    padding-bottom: 30px;
    margin-bottom: 40px;
    margin-top: 60px;

    .slick-dots {
      bottom: -20px;
    }

    .slick-arrow {
      left: 0;
      &.slick-prev {
        margin-left: -15px;
        transform: translate(0, 0);
      }
      &.slick-next {
        left: auto;
        right: 0;
        margin-right: -15px;
        transform: translate(0, 0);
      }
    }
  }
  @include r($sm) {
    padding-bottom: 25px;
    margin-bottom: 5px;
    margin-top: 30px;

    .slick-dots {
      bottom: -10px;
    }

    .slick-arrow {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
  }
}//news-slider__wrap

.news-bottom-separator {
  margin-top: 80px;
  margin-bottom: 80px;
  @include r($xl) {
    margin-bottom: 60px;
  }
  @include r($md) {
    margin-top: 60px;
  }
  @include r($sm) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.news-paginator {
    background: url(../images/bg-lines-seamless.png) 0 0 repeat;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    min-height: 80px;
    @include r($xl) {
        min-height: 70px;
    }
    @include r($sm) {
        padding-bottom: 70px;
    }
    .show-all {
        flex: 0 1 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
            display: none;
        }
        @include r($md) {
            flex: 0 1 30%;
        }
        @include r($sm) {
            bottom: 0;
            position: absolute;
            height: 70px;
            border-top: 1px solid rgba(#796e44, .7);
            width: 100%;
        }
    }
}
.desktop {
    .news-paginator {
        .show-all {
            .show-all__inner {
                transition: all $anim ease-in-out;
            }
            &:hover {
                .show-all__inner {
                    color: $color-main;
                }
            }
        }
        &:hover {
            .show-all:not(.hovered),
            .nav-item:not(.hovered) {
                opacity: .7;
            }
        }
    }
}

.wallpaper {
    margin-top: 60px;
    &-list {
        align-items: flex-start;
        justify-content: flex-start;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        &__item {
            flex: 0 0 12.5%;
            a {
                background: rgba($dark-grey, .15);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 12.5px;
                border: 1px solid $dark;
                border-width: 0 1px 1px 0;
                text-decoration: none!important;
                .desktop & {
                    transition: all $anim ease-in-out;
                    &:hover {
                        background: rgba($dark-grey, .35);
                    }
                }
            }
        }
    }
    @include r($md) {
        margin-top: 40px;
        &-list {
            &__item {
                flex: 0 0 16.6666666%;
            }
        }
    }
    @include r($sm) {
        &-list {
            &__item {
                flex: 0 0 25%;
                font-size: 14px;
            }
        }
    }
    @include r($xs) {
        &-list {
            &__item {
                flex: 0 0 33.3333%;
            }
        }
    }
    @include r(413px) {
        &-list {
            &__item {
                flex: 0 0 50%;
            }
        }
    }
}
