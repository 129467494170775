.maps {
  background:#000;
  height: 100vh;
  min-height: 720px;
  overflow: hidden;
  .section__top_text {
    color: #fff;
  }
  html.html--device & {
    padding-top:0;
    padding-bottom:0;
    height: auto;
  }
  &__head {
    text-align: center;
    position: absolute;
    top: 70px;
    @include r($lg) {
      top: 55px;
    }
    html.html--device & {
      position:absolute;
      top:60px;
    }
  }
  &__clouds {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    pointer-events:none;
    html.html--device & {
      display:none;
    }
    &__item {
      background-color:transparent;
      background-position:center center;
      background-repeat:no-repeat;
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
    &_upper__item {
      transition:all .5s ease-out;
    }
    &_bottom_left {
      transform-origin:top right;
    }
    &_bottom_right {
      transform-origin:top left;
    }
    &_top_left {
      transform-origin:bottom right;
    }
    &_top_right {
      transform-origin:bottom left;
    }
  }
  &__map {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    overflow:hidden;
    &_img,
    &_img_device {
      display:block;
      position:absolute;
      max-width:none;
      .html--desktop & {
        filter:blur(1.5px);
        transition:filter 1.5s cubic-bezier(0.5, 2, 0.75, -0.05),
          transform 1s ease-out;
      }
    }
    &_img_device {
      display:none;
    }
    .html--device & {
      position:relative;
      overflow:hidden;
      height:768.375px;
      @media (max-width:1280px) {
        height:720px;
      }
      &_inner {
        position:relative;
        height:100%;
        overflow:auto;
        @media (max-width:1024px) {
          margin-left:-10%;
          margin-right:-10%;
        }
        @media (max-width:640px) {
          margin-left:-20%;
          margin-right:-20%;
        }
        @include r($width_mob) {
          margin-left:-40%;
          margin-right:-40%;
        }
      }
      &_img {
        display:none;
      }
      &_img_device {
        display:block;
        width:1366px;
        @media (max-width:1280px) {
          width:1280px;
        }
      }
    }
    &_points {
      position:absolute;
      .html--device & {
        width:1280px;
        height:720px;
      }
      &__item {
        display:block;
        position:absolute;
        width:48px;
        height:48px;
        margin-left:-24px;
        margin-top:-24px;
        cursor:pointer;
        transform:scale(0);
        &_content {
          width:48px;
          height:48px;
          border-radius:50%;
          border:4px solid #ffc83c;
          background:rgba(#000, 0.2);
          position:absolute;
          display:flex;
          align-items:center;
          justify-content:center;
          &:before {
            content:'';
            border-radius:50%;
            height:275%;
            width:275%;
            position:absolute;
            box-shadow:inset 0 0 10px 1px #ffc83c;
            opacity:0;
            transform:scale(0.3636);
            .freezed & {
              display:none;
            }
          }
        }
        @include r($lg) {
          width:40px;
          height:40px;
          margin-left:-20px;
          margin-top:-20px;
          &_content {
            width:40px;
            height:40px;
          }
        }
        &:hover {
          .html--desktop & {
            .maps__map_points__item_content {
              &:before {
                display:none;
              }
            }
            svg {
              transform:scale(1) rotate(0deg);
              path {
                fill:rgba(#ffc83c, 0.5);
              }
            }
          }
        }
        &.show {
          animation:scaleIn 0.5s;
          animation-fill-mode:forwards;
        }
        &.blink {
          .maps__map_points__item_content {
            &:before {
              animation:blinkMapDot 4s infinite;
            }
          }
        }
        &:nth-child(2) {
          animation-delay:0.25s;
          .maps__map_points__item_content {
            &:before {
              animation-delay:1s;
            }
          }
        }
        &:nth-child(3) {
          animation-delay:0.5s;
          .maps__map_points__item_content {
            &:before {
              animation-delay:2s;
            }
          }
        }
        &:nth-child(4) {
          animation-delay:0.75s;
          .maps__map_points__item_content {
            &:before {
              animation-delay:3s;
            }
          }
        }
        &_img {
          display:block;
          max-width:24px;
        }
        svg {
          display:block;
          box-sizing:border-box;
          position:absolute;
          overflow:visible;
          height:368px;
          width:368px;
          transform-origin:50% 50%;
          transform:scale(0) rotate(0deg);
          transition:transform 0.25s ease-out;
          pointer-events:none;
          circle {
            fill:none;
            stroke:#ffc83c;
            stroke-width:1;
          }
          path {
            fill:rgba(#ffc83c, 0);
            transition:all 0.25s ease-out;
          }
        }
      }
    }
  }
  &__drone {
    position:absolute;
    top:95px;
    left:30px;
    right:30px;
    bottom:30px;
    pointer-events:none;
    @include r($xl) {
      top:85px;
    }
    @include r($lg) {
      top:70px;
      left:20px;
      right:20px;
      bottom:20px;
    }
    .html--device & {
      display:none !important;
    }
    &__top {
      &_left {
        position:absolute;
        top:0;
        left:0;
        width:calc(50% - 240px);
        &:before {
          content:'';
          position:absolute;
          top:0;
          left:0;
          width:70px;
          height:70px;
          border:2px solid #fefefe;
          border-width:2px 0 0 2px;
        }
        &:after {
          background:rgba(#fefefe, 0.4);
          content:'';
          height:1px;
          position:absolute;
          top:0;
          left:80px;
          right:0;
        }
      }
      &_right {
        position:absolute;
        top:0;
        right:0;
        width:calc(50% - 240px);
        &:before {
          content:'';
          position:absolute;
          top:0;
          right:0;
          width:70px;
          height:70px;
          border:2px solid #fefefe;
          border-width:2px 2px 0 0;
        }
        &:after {
          background:rgba(#fefefe, 0.4);
          content:'';
          height:1px;
          position:absolute;
          top:0;
          right:80px;
          left:0;
        }
      }
    }
    &__left {
      position:absolute;
      top:80px;
      left:0;
      bottom:80px;
      width:1px;
      background:rgba(#fefefe, 0.4);
    }
    &__right {
        position:absolute;
        top:80px;
        right:0;
        bottom:80px;
        width:1px;
        background:rgba(#fefefe, 0.4);
    }
    &__bottom {
      &_left {
        position:absolute;
        bottom:0;
        left:0;
        width:50%;
        &:before {
          content:'';
          position:absolute;
          bottom:0;
          left:0;
          width:70px;
          height:70px;
          border:2px solid #fefefe;
          border-width:0 0 2px 2px;
        }
        &:after {
          background:rgba(#fefefe, 0.4);
          content:'';
          height:1px;
          position:absolute;
          bottom:0;
          left:80px;
          right:0;
        }
      }
      &_right {
        position:absolute;
        bottom:0;
        right:0;
        text-align:right;
        width:50%;
        &:before {
          content:'';
          position:absolute;
          bottom:0;
          right:0;
          width:70px;
          height:70px;
          border:2px solid #fefefe;
          border-width:0 2px 2px 0;
        }
        &:after {
          background:rgba(#fefefe, 0.4);
          content:'';
          height:1px;
          position:absolute;
          bottom:0;
          right:80px;
          left:0;
        }
      }
      &_text {
        position:absolute;
        bottom:20px;
        color:rgba(#fefefe, 0.8);
        font-family:'vcr_osd_mono';
        text-transform:uppercase;
        font-size:14px;
        line-height:1.3;
        font-weight:500;
        @include r($lg) {
          font-size:12px;
        }
      }
      &_left_text {
        left:20px;
        span {
          display:block;
          position:relative;
          overflow:hidden;
          white-space:nowrap;
          width:0;
        }
        &_topline {
          .maps.active & {
            animation:typing 0.375s steps(6, end);
            animation-fill-mode:forwards;
            animation-delay:0.375s;
          }
        }
        &_bottomline {
          .maps.active & {
            animation:typing .75s steps(20, end);
            animation-fill-mode:forwards;
            animation-delay:1s;
          }
        }
      }
      &_right_text {
        right:20px;
        text-align:right;
        span {
          display:inline-block;
          margin-left:30px;
          i {
            display:inline-block;
            font-style:normal;
            min-width:40px;
            padding-left:10px;
          }
        }
      }
    }
  }
  &__help {
    display:none;
    .html--desktop & {
      display:none;
    }
    position:absolute;
    bottom:35px;
    left:50%;
    width:75px;
    margin-left:-37.5px;
    @media (max-width:1200px) {
      display:block;
    }
    svg {
      position:relative;
      overflow:visible;
      width:100%;
      height:100%;
      path {
        fill:transparent;
        stroke:rgba(#fefefe, 0.75);
        stroke-width:2;
      }
    }
    img {
      display:block;
      position:absolute;
      top:0.5px;
      left:50%;
      width:33px;
      margin-left:-17px;
      transform:translate3d(0, 0, 0);
      animation:swipeHelp 2.5s infinite;
    }
  }
}
.maps__clouds_lower__item.maps__clouds_bottom_left {
  background-image:url(../images/content/about/cloud-lower-bottom-left.png);
}
.maps__clouds_lower__item.maps__clouds_bottom_right {
  background-image:url(../images/content/about/cloud-lower-bottom-right.png);
}
.maps__clouds_lower__item.maps__clouds_top_left {
  background-image:url(../images/content/about/cloud-lower-top-left.png);
}
.maps__clouds_lower__item.maps__clouds_top_right {
  background-image:url(../images/content/about/cloud-lower-top-right.png);
}
.maps__clouds_upper__item.maps__clouds_bottom_left {
  background-image:url(../images/content/about/cloud-upper-bottom-left.png);
}
.maps__clouds_upper__item.maps__clouds_bottom_right {
  background-image:url(../images/content/about/cloud-upper-bottom-right.png);
}
.maps__clouds_upper__item.maps__clouds_top_left {
  background-image:url(../images/content/about/cloud-upper-top-left.png);
}
.maps__clouds_upper__item.maps__clouds_top_right {
  background-image:url(../images/content/about/cloud-upper-top-right.png);
}
.maps.active {
  .maps__map_img {
    filter:blur(0);
    transform:scale(1.25);
  }
  .maps__clouds_upper__item {
    opacity:0;
    transform:scale(1.5);
  }
  .maps__clouds_lower__item {
    animation:cloudsLower .5s;
    animation-fill-mode:forwards;
  }
}
.maps__popup {
  pointer-events:none;
  opacity:0;
  position:fixed;
  width:360px;
  transform:translate3d(-50%, -50%, 0);
  z-index:100;
}
.maps__popup_content {
  opacity:0;
  background:rgba(#131414, 0.5);
  padding:112px 10px 40px;
  position:relative;
  overflow:visible;
  text-align:center;
  transition:opacity 0.25s ease-in-out, background 0.5s ease-in-out;
  @include r($lg) {
    padding-left:0;
    padding-right:0;
  }
}
.maps__close {
  border: solid 1px rgba($dark-grey, .6);
  background-color: rgba($dark, .6);
  display:none;
  position:absolute;
  right:30px;
  top:100px;
  height:60px;
  width:60px;
  transition:all $anim ease-in-out;
  path {
    fill:transparent;
    stroke:$white;
    stroke-width:2;
  }
  @include r($lg) {
    right:20px;
    top:80px;
  }
  @include r($md) {
    width:40px;
    height:40px;
  }
  @include r($width_mob) {
    top: 60px;
    right: 0;
  }
}
.maps__popup_img {
  opacity:0;
  transform:translate3d(0, -20px, 0);
  position:absolute;
  left:0;
  right:0;
  max-width: 100%;
  top:-84px;
  transition:all 0.25s ease-in-out;
  z-index:1;
  @include r($lg) {
    top:-50px;
  }
}
.maps__popup_container {
  position:relative;
  z-index:1;
  padding-left: 30px;
  padding-right: 30px;
}
.maps__popup_icon {
  display:inline-block;
  opacity:0;
  height:20px;
  width:20px;
  margin-bottom:30px;
  transform:rotateY(-360deg) translate3d(0, 0, 0);
  transition:all 0.5s ease-in-out;
  position:relative;
  z-index:1000;
  backface-visibility:visible;
  transform-style:preserve-3d;
  @include r($lg) {
    margin-bottom:20px;
  }
}
.maps__popup_info {
  opacity:0;
  transform:translate3d(0, 20px, 0);
  position:relative;
  z-index:1;
  transition:all 0.25s ease-in-out;
  backface-visibility:visible;
  transform-style:preserve-3d;
}
.maps__popup_heading {
  color:#fefefe;
  backface-visibility:visible;
  transform-style:preserve-3d;
}
.maps__popup.visible {
  opacity:1;
  pointer-events:all;
  @include r($lg) {
    width:320px;
  }
  .maps__popup_content {
    background:rgba(#131414, 0.95);
    opacity:1;
  }
  .maps__popup_img,
  .maps__popup_info {
    opacity:1;
    transform:translate3d(0, 0, 0);
    transition-delay:0.25s;
  }
  .maps__popup_icon {
    opacity:1;
    transform:rotateY(0deg) translate3d(0, 0, 0);
    transition-delay:0.25s;
  }
}
html.html--device {
  .maps__popup {
    display:none;
  }
  .maps__close {
    display:block;
    z-index:1045;
    position:fixed;
  }
  .mfp-wrap {
    .maps__popup {
      opacity:1;
      pointer-events:all;
      position:relative;
      z-index:100;
      min-height:100%;
      width:100%;
      transform:translate3d(0, 0, 0);
      top:0 !important;
      left:0 !important;
      background:#131414;
      display:flex;
      align-items:center;
      justify-content:center;
      .maps__popup_content {
        background:rgba(#131414, 0.95);
        opacity:1;
        max-width:360px;
        padding-top:40px;
        margin-left:auto;
        margin-right:auto;
      }
      .maps__popup_img {
        position:relative;
        top:0;
        margin-bottom:-70px;
        @media (orientation:landscape) and (max-width:812px) {
          margin-bottom:-90px;
        }
      }
      .maps__popup_img,
      .maps__popup_info {
        opacity:1;
        transform:translate3d(0, 0, 0);
        transition-delay:0.25s;
      }
      .maps__popup_icon {
        opacity:1;
        transform:rotateY(0deg) translate3d(0, 0, 0);
        transition-delay:0.25s;
        margin-bottom:15px;
      }
    }
  }
}

/* Animations */
@keyframes typing {
  from {
    width:0;
  }
  to {
    width:100%;
  }
}

@keyframes blinkMapDot {
  0%,
  100% {
    opacity:0;
    transform:scale(0.333333);
  }
  20% {
    opacity:1;
    transform:scale(1);
  }
  30% {
    opacity:0;
    transform:scale(1);
  }
}

@keyframes scaleIn {
  from {
    transform:scale(0);
  }
  to {
    transform:scale(1);
  }
}

@keyframes cloudsLower {
  0% {
    opacity:0.5;
    transform:scale(1);
  }
  50% {
    opacity:1;
  }
  100% {
    opacity:0;
    transform:scale(1.5);
  }
}

@keyframes swipeHelp {
  0%,
  50%,
  100% {
    transform:translate3d(0, 0, 0);
  }
  10% {
    transform:translate3d(-4px, 0, 0);
  }
  20% {
    transform:translate3d(3px, 0, 0);
  }
  30% {
    transform:translate3d(-2px, 0, 0);
  }
  40% {
    transform:translate3d(1px, 0, 0);
  }
}
