.filter-line__wrap {
  display: block;
  width: 100%;
  position: relative;
  margin: 0 0 80px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-image: linear-gradient(to right, rgba($grey, .3) 33.333%, rgba(255, 255, 255, 0) 0%);
    background-position: top left;
    background-size: 6px 2px;
    background-repeat: repeat-x;
  }

  @include r($lg) {
    margin-bottom: 60px;
  }
  @include r($sm) {
    margin-bottom: 40px;
  }
}
.filter-line {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  position: relative;
  overflow-y: auto;

  &:before {
    content: '';
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    border-bottom: 2px dotted $grey;
  }

  & > li {
    display: block;
    list-style: none;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
  a {
    display: block;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    color: $grey;
    text-transform: uppercase;
    position: relative;
    padding: 0 0 20px;
    transition: color $anim ease;

    &:after {
      content: '';
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: $color-main;
    }

    &.active {
      cursor: default;
      color: $white;

      &:after {
        display: block;
      }
    }
    .desktop &:not(.active):hover {
      color: $color-main;
    }
  }
}//filter-line
