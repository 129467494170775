.spoiler {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 30px 0 40px;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.spoiler-title {
  cursor: pointer;
  order: 2;
  display: inline-flex;
  position: relative;
  padding-right: 28px;
  font-size: 18px;
  line-height: 21px;
  color: $color-main;
  position: relative;
  text-decoration: underline;
  .desktop &:hover {
    color: $hover-color;
  }
  &:after {
    content: '';
    right: 0;
    top: 50%;
    margin-top: -4px;
    width: 12px;
    height: 12px;
    position: absolute;
    background-image: url(../images/arrowdown.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.active {
    &:after {
      transform: rotate(180deg);
    }
  }
  @include r($lg) {
    font-size: 16px;
    &:after {
      margin-top: -5px;
    }
  }
}

.spoiler-toggle {
  display: none!important;
}

.spoiler-link__wrap {
  display: block;
  width: 100%;
  margin: 0;
}

.spoiler-link {
  display: inline-block;
  position: relative;
  padding-right: 28px;
  font-size: 18px;
  line-height: 21px;
  color: $color-main;
  text-decoration: none;
  .text-block & {
    text-decoration: none;
  }

  .spoiler-link__icon {
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    width: 12px;
    height: 12px;
    margin-top: -4px;

    .icon {
      fill: $color-main;
    }
  }
  .less {
    display: none;
  }

  &.active {
    .more {
      display: none;
    }
    .less {
      display: block;
    }

    .spoiler-link__icon {
      transform: rotate(180deg);
    }
  }
  .desktop &:hover {
    color: $hover-color;
  }
  @include r($lg) {
    font-size: 16px;
    .spoiler-link__icon {
        margin-top: -5px;
    }
  }
}//spoiler-link
p + .spoiler {
  margin-top: -10px;
}

.desktop {
  .text-block {
    .spoiler-link {
      &:hover {
        color: $hover-color;
      }
    }
  }
}

.desktop {
  .text-block {
    .spoiler-title {
      &:hover {
        color: $hover-color;
      }
    }
  }
}

.spoiler-content {
  display: none;
  padding-bottom: 30px;
  padding-top: 1px;
  ul {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
