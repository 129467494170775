.social-stick {
  display: block;
  position: fixed;
  top: 50%;
  left: 100%;
  transform: translate(0, -50%);
  z-index: 20;

  @include r($md) {
    display: none;
    visibility: hidden;
  }

  .social-stick__list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    margin: 0;
    padding: 0;

    & > li {
      display: block;
      height: 100%;
      list-style: none;
      margin-right: 1px;
    }
  }
  &.hide {
    .stick-link {
      transform: translate3d(0, 0, 0);
    }
  }
  .stick-link {
    display: block;
    height: 50px;
    position: relative;
    padding: 18px 17px 18px 45px;
    font-size: 11px;
    line-height: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    transform: translate3d(-50px, 0, 0);
    transition: transform $anim ease;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $dark-grey;
      opacity: .15;
      transition: all $anim ease;
    }

    .stick-link__text {
      position: relative;
      opacity: 0;
      transition: all $anim ease;
    }

    .stick-link__icon {
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 50%;
      margin-left: -10px;
      transition: all $anim ease;
      left: 15px;
      margin-left: 0;
    }
    .icon {
      fill: $white;
    }

    .desktop &:hover {
      transform: translate3d(-100%,0,0);
      .stick-link__text {
        opacity: 1;
      }
      .stick-link__icon {
        left: 15px;
        margin-left: 0;
      }
      &:before {
        opacity: 1;
        background: $color-main;
      }
      &.fb {
        &:before {
          background: $social-fb;
        }
      }
      &.twitter {
        &:before {
          background: $social-twitter;
        }
      }
      &.vk {
        &:before {
          background: $social-vk;
        }
      }
      &.od {
        &:before {
          background: $social-od;
        }
      }
      &.forum {
        color: #000;
        .icon {
          fill: #000;
        }
        &:before {
          background: $color-main;
        }
      }
    }
  }
}//social-stick
