

.icons-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin: 0 0 30px;
  padding: 0;

  & > li {
    display: block;
    width: 140px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 5px;
  }
  .name {
    padding: 20px 0 5px;
  }
  .icon {
    width: 50px;
    height: 50px;
    fill: $white;
  }
}

.text-block {
  color: $base-color;
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: .5px;
  margin-bottom: 60px;
  h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
    color: $white;
  }
  p, ul, ol, h2, .h2, h3, .h3, h4, .h4, h5, .h5, .wp-caption, figcaption {
    max-width: 1000px;
  }

  & > * {
    margin: 0 0 40px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2, h4 {
    margin-bottom: 20px;
    @include r($md) {
      margin-bottom: 15px;
    }
  }

  h2 + img,
  h3 + img {
    margin-top: 40px;
    @include r($md) {
      margin-top: 25px;
    }
    @include r($sm) {
      margin-top: 20px;
    }
  }

  iframe {
    display: block;
    margin-bottom: 60px;
    margin-top: 60px;
    max-width: 100%;
    @include r($md) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @include r($sm) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
  a:not(.btn) {
    text-decoration: underline;
    transition: color $anim ease;

    .desktop &:hover {
      color: $color-main;
    }
  }

  .wp-caption,
  figcaption {
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    margin: 20px 0;
    @include r($lg) {
      font-size: 14px;
      line-height: 20px;
    }
    @include r($sm) {
      margin-top: 15px;
    }
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 0 20px;
    @include r($sm) {
      margin-bottom: 15px;
    }
  }

  blockquote {
    display: block;
    width: 100%;
    position: relative;
    padding: 30px 40px;
    background: $main-black;
    margin-top: 60px;
    margin-bottom: 60px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 7px;
      background: url(../images/bg-lines-2.png) 0 0 repeat;
    }
    @include r($md) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @include r($sm) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  ul:not(.slick-dots):not(.none-list-style) {
    padding: 0;
    li {
      display: block;
      list-style: none;
      position: relative;
      padding-left: 20px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 13px;
        left: 0;
        width: 4px;
        height: 4px;
        background: $color-main;
      }
      @include r($lg) {
        &:before {
          top: 10px;
        }
      }
    }
  }
  ol {
    padding: 0;

    li {
      list-style: none;
      display: block;
      width: 100%;
      position: relative;
      padding-left: 50px;
      counter-increment: ol-couter;
      margin: 0 0 15px;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: "0" counter(ol-couter);
        position: absolute;
        top: 0;
        left: 0;
        color: $color-main;
        font-weight: 500;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 35px;
        top: 4px;
        bottom: 4px;
        width: 1px;
        background: $dark-grey;
      }
    }
  }
  @include r($lg) {
    font-size: 16px;
    line-height: 1.5;
    & > * {
      margin: 0 0 40px;
    }
  }
  @include r($lg) {
    & > * {
      margin: 0 0 30px;
    }
  }
  @include r($md) {
    margin-bottom: 50px;
  }
  @include r($sm) {
    margin-bottom: 30px;
  }
}//text-block

.small-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.39px;
  color: $base-color;
}

.h1,
h1 {
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 56px;
  line-height: 1.21;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin: 0 0 80px;
  max-width: 960px;
  @include r($xl) {
    font-size: 46px;
    line-height: 1.22;
    margin: 0 0 50px;
    max-width: 800px;
  }
  @include r($lg) {
    font-size: 36px;
    line-height: 1.28;
    margin: 0 0 50px;
    max-width: 640px;
  }
  @include r($md) {
    font-size: 26px;
    line-height: 1.38;
    margin: 0 0 40px;
    max-width: 560px;
  }
}

.h2,
h2 {
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 34px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: .5px;
  text-transform: uppercase;
  margin: 0 0 20px;

  @include r($xl) {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
  @include r($md) {
    margin-bottom: 15px;
  }
}

.h3,
h3 {
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0 0 20px;

  @include r($xl) {
    font-size: 20px;
  }
}

.h4,
h4 {
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: uppercase;
  margin: 0 0 20px;
  padding-left: 20px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 0;
    width: 2px;
    background: $color-main;
  }

  @include r($xl) {
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 20px;
  }
  @include r($lg) {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 15px;
  }
}

h5,
.h5 {
  display: block;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 0 0 20px;
  position: relative;

  @include r($xl) {
    font-size: 16px;
    line-height: 1.34;
    letter-spacing: .4px;
  }
}

.lead {
  display: block;
  width: 100%;
  font-size: 22px;
  line-height: 1.45;
  margin: 0 0 60px;
  letter-spacing: 0.5px;
  p {
    max-width: 1000px;
  }

  &:last-child {
    margin-bottom: 0;
  }
  @include r($xl) {
    font-size: 20px;
    line-height: 1.6;
    margin: 0 0 50px;
    letter-spacing: normal;
  }
  @include r($lg) {
    margin: 0 0 40px;
  }
}//lead

.tip-anchor {
  display: inline-block;
  outline: 1px solid rgba($color-main, .4);
  padding: 0 3px;
  margin: 0 5px;
  position: relative;
  background: rgba(255,200,60,.05);
  z-index: 5;

  &.activated {
    color: $main-black;
    background: $color-main;
  }
  .tip {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    top: 100%;
    right: -1px;
    padding: 20px 30px;
    background: $main-black;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .39px;
    color: $base-color;
    cursor: default;
    max-width: calc(100vw - 40px);
  }
  .tip__img {
    display: block;
    width: 72px;
    min-width: 72px;
    margin-right: 20px;

    img {
      display: block;
      max-width: 100%;
      margin: 0;
    }
  }
  .tip__text {
    display: block;
    width: 400px;
  }
  .tip__close {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 18px;
    height: 18px;
    padding: 5px;
    cursor: pointer;

    .icon {
      fill:$grey;
      transform: fill $anim ease;
    }
    .desktop &:hover {
      .icon {
        fill: $color-main;
      }
    }
  }
}


.social-block {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  cursor: default;
  padding: 0 0 80px;

  .social-forum {
    display: block;
  }
  .social-forum__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: auto;
    min-height: 50px;

    .desktop &:hover {
      .social-forum__title {
        color: $color-main;
      }
      .social-forum__icon {
        .icon {
          fill: $color-main;
        }
      }
    }
  }
  .social-share__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;

    & > li {
      display: block;
      height: 100%;
      list-style: none;
      margin-right: 1px;
    }
  }
  .share-link {
    display: block;
    height: 50px;
    position: relative;
    padding: 18px 17px 18px 45px;
    font-size: 11px;
    line-height: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: $white;
    text-align: center;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $dark-grey;
      opacity: .15;
      transition: all $anim ease;
    }

    .share-link__text {
      position: relative;
      opacity: 0;
      transition: all $anim ease;
    }

    .share-link__icon {
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 50%;
      margin-left: -10px;
      transition: all $anim ease;
    }
    .icon {
      fill: $white;
    }

    .desktop &:hover {
      .share-link__text {
        opacity: 1;
      }
      .share-link__icon {
        left: 15px;
        margin-left: 0;
      }
      &:before {
        opacity: 1;
      }
      &.fb {
        &:before {
          background: $social-fb;
        }
      }
      &.twitter {
        &:before {
          background: $social-twitter;
        }
      }
      &.vk {
        &:before {
          background: $social-vk;
        }
      }
      &.od {
        &:before {
          background: $social-od;
        }
      }
    }
  }
  .social-share__title,
  .social-forum__title {
    display: block;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    color: $white;
    transition: color $anim ease;
  }
  .social-share__title {
    margin: 0 0 20px;
  }
  .social-forum__title {
    margin: 0 20px 0 0;
  }
  .social-forum__link {

  }
  .social-forum__icon {
    display: block;
    width: 28px;
    height: 28px;

    .icon {
      fill: $white;
      transition: fill $anim ease;
    }
  }
  @include r($xl) {
    padding-bottom: 60px;
  }
  @include r($md) {
    flex-wrap: wrap;
    padding: 0 0 60px;

    .social-forum {
      display: block;
      width: 100%;
      margin: 60px 0 0;

      &:first-child {
        margin-top: 0;
      }
    }
    .social-forum__link {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      margin-left: auto;
      min-height: 0;
      justify-content: center;
    }
    .social-share {
      width: 100%;
    }
    .social-share__list {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;

      & > li {
        display: block;
        height: 100%;
        width: 100%;
        list-style: none;
        margin-right: 1px;
      }
    }
    .social-share__title,
    .social-forum__title {
      font-size: 14px;
      line-height: 26px;
      text-align: center;
    }
    .social-share__title {
      margin: 0 0 20px;
    }
    .social-forum__title {
      margin: 0 20px 0 0;
    }
    .social-forum__link {

    }
    .social-forum__icon {
      display: block;
      width: 20px;
      height: 20px;
    }
  }
  @include r($sm) {
    .social-forum {
      margin-top: 40px;
    }
    .share-link {
      width: 100%;
      padding: 0;

      .share-link__text {
        display: none;
      }
    }
  }
}//social-block

.social-block + .show-all__wrap {
  margin-top: 0;
}

.block-label {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  @include r($xl) {
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
  }
}
