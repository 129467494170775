.table-simple {
    display: table;
    width: 100%;
    padding: 0;
    margin: 0 0 60px;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;

    &:last-child {
        margin-bottom: 0;
    }

    .t_row {
        display: table-row;
        vertical-align: middle;

        &:nth-child(2n+1) {
            background: $table-row-bg;
        }

        &.t_row-head {
            background: rgba($dark-grey, .3);
            color: $grey;
        }
    }

    .t_cell {
        display: table-cell;
        padding: 15px 20px;
    }

    .t_title {
        display: none;
    }

    .t_cell-head {
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        font-weight: 500;
    }
    @include r($xl) {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    @include r($sm) {
        display: block;
        border-top: 1px solid #242426;

        .t_row {
            display: block;

            &.t_row-head {
                display: none;
            }
        }

        .t_cell {
            display: block;
        }

        .t_title {
            display: block;
            font-size: 12px;
            line-height: 14px;
            font-weight: 500;
            margin: 0 0 5px;
            text-transform: uppercase;
        }

        .t_text {
            color: $white;
        }
    }
}

table {
    width: 100%;
    padding: 0;
    margin: 0 0 60px;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;

    &:last-child {
        margin-bottom: 0;
    }

    tr {
        vertical-align: middle;

        &:nth-child(2n) {
            background: $table-row-bg;
        }

        th,
        td {
            padding: 15px 20px;

            p {
                margin: 0;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 40px 15px;
                position: relative;
                overflow: visible;
                width: 100%;
                min-height: 117px;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -15px;
                    left: -20px;
                    right: -19px;
                    bottom: -14px;
                    background: rgb(27, 29, 29);
                }

                img {
                    margin-bottom: 0!important;
                    width: auto;
                    max-height: 37px;
                    position: relative;
                }

                .desktop & {
                    &:before {
                        transition: opacity $anim ease-in-out;
                    }

                    &:hover {
                        &:before {
                            background: rgb(33, 34, 36);
                        }
                    }
                }
            }
        }
    }

    thead {
        tr {
            background: rgba($dark-grey, .3);
            color: $grey;

            td,
            th {
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;
                font-weight: 500;
            }
        }
    }
    @include r($xl) {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    @include r($sm) {
        font-size: 12px;
        line-height: 14px;

        tr {
            th,
            td {
                padding: 10px;
                a {
                    padding: 20px 10px;
                    min-height: 64px;

                    &:before {
                        top: -10px;
                        left: -10px;
                        right: -9px;
                        bottom: -9px;
                    }

                    img {
                        max-height: 24px;
                    }
                }
            }
        }

        thead {
            tr {
                td,
                th {
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }
    }
    @include r($xs) {
        tr {
            th,
            td {
                a {
                    padding: 10px 2px;
                    min-height: 40px;

                    img {
                        max-height: 20px;
                    }
                }
            }
        }
    }
}
