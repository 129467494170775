@import "./normalize";
@import "vars";
@import "./mixins";
@import "fonts";
@import "./sprite_generated";
@import "./grid";
@import "../../libs/slick-carousel/slick/slick.scss";

*,
*::before,
*::after {
    box-sizing: border-box;
}

:active,
:focus,
:hover {
    outline: 0;
}


html,
body {
    height: 100%;
    padding: 0;
}

html {
    font-size:10px;
    line-height:1.2;
    overflow-y:scroll;

    &.overflow-hidden {
        overflow: hidden;
    }

    &.scroll-lock {
        body {
            overflow: hidden;
        }
    }
}

body {
    display:block;
    width: 100%;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $base-bg;
    color: $base-color;
    font-family: $font-default;
    font-weight: 300;
    font-size:1.4rem;
}

.layout {
    position: relative;
    min-height: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}

iframe {
    border: none;
    margin-bottom: 30px;
    max-width: 100%;

    &:last-child {
        margin-bottom: 0;
    }
    @include r($sm) {
        width: 100%;
        height: 52vw;
    }
}


a {
    text-decoration:none;
    color:inherit;
}
a,
input,
button {
    outline: none;
    &:active, &:focus, &:hover {
        outline: none;
    }
}
a {
    cursor:pointer;
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}
p {
    margin: 15px 0;
}

p:empty {
    display: none;
}
b,
strong {
    font-weight: 500;
    color: $white;
}

input {
    -webkit-appearance: none;
    border-radius: 0;
}

.svg-store {
    display: none;
    height: 0;
    overflow: hidden;
}

.icon {
    display:block;
    width:100%;
    height:100%;
}


.block {
    display: block;
    width: 100%;
    height: 100px;
    background: #ccc;
    margin-bottom: 50px;

    &.block-1 {
        font-size: 30px;
        font-weight: bold;
        padding: 10px;
    }
}

.yellow {
    color: $color-main;
}

.breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    text-transform: uppercase;
    color: $grey;
    cursor: default;
    margin: 0 0 15px;

    & > li {
        display: flex;
        flex-direction: row;
        align-items: center;
        list-style: none;
        margin-right: 11px;

        &:after {
            content: '/';
            display: block;
            margin-left: 11px;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
    a {
        transition: color $anim ease;
        .desktop &:hover {
            color: $color-main;
        }
    }
}//breadcrumbs

hr,
.line-separator {
    display: block;
    width: 100%;
    height: 1px;
    background: $dark-grey;
    margin: 60px 0;
    outline: none;
    border: none;
    @include r($xl) {
        margin-top: 50px;
    }
    @include r($md) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    @include r($sm) {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}


.slick-arrow {
    display: block;
    position: absolute;
    top: 50%;
    width: 50px;
    height: 60px;
    border: none;
    padding: 0;
    margin-top: -30px;
    background: none;
    outline: none;
    z-index: 50;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $dark;
        opacity: .6;
        transition: all $anim ease;
    }
    .icon {
        position: relative;
        width: 18px;
        height: 18px;
        fill: $white;
        margin: auto;
        transition: fill $anim ease;
    }
    &.slick-disabled {
        opacity: .3;
        cursor: default;
    }
    &.slick-prev {
        left: 40px;
    }
    &.slick-next {
        left: auto;
        right: 40px;
    }
    .desktop &:not(.slick-disabled):hover {
        .icon {
            fill: $main-black;
        }
        &:after,
        &:before {
            border-color: $color-main;
            background-color: $color-main;
            opacity: 1;
        }
        &:after {
            background: none;
        }
    }
    @include r(1800px) {
        &.slick-prev {
            left: 20px;
        }
        &.slick-next {
            right: 20px;
        }
    }//$xl
    @include r($table-screen) {
        display: none !important;
    }
}
.slick-dots {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 40px;
    padding: 0;
    margin: 0;
    left: 0;
    right: 0;

    li {
        display: block;
        list-style: none;
        margin: 0 20px;

        &.slick-active {
            button {
                &:after {
                    width: 100%;
                }
            }
        }
    }
    button {
        font-size: 0;
        line-height: 0;
        padding: 0;
        margin: 0;
        width: 60px;
        height: 20px;
        background: none;
        border-radius: 0;
        border: none;
        position: relative;

        &:before {
            background-image: linear-gradient(to right, rgba($grey, .6) 33.333%, rgba(255, 255, 255, 0) 0%);
            background-position: top left;
            background-size: 6px 2px;
            background-repeat: repeat-x;
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            height: 2px;
            background: $color-main;
            bottom: 0;
            width: 0;
            transition: width $anim ease;
        }
        .slide-preview {
            display: block;
            position: absolute;
            width: 160px;
            bottom: 0;
            left: 50%;
            margin-left: -80px;
            margin-bottom: 15px;
            transition: all $anim ease;
            opacity: 0;
            visibility: hidden;
            transform: scale(0);
            img {
                display: block;
                width: 100%;
            }
        }
        .desktop &:hover {
            &:before {
                background-image: linear-gradient(to right, rgba($color-main, 1) 33.333%, rgba(255, 255, 255, 0) 0%);
            }
            .slide-preview {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
            }
        }
    }
    @include r($sm) {
        li {
            margin: 0 10px;
        }
        button {
            width: 40px;
            height: 20px;
        }
    }
}//slick-dots


.show-all__wrap {
    display: block;
    width: 100vw;
    left: 50%;
    margin-left: -50vw;
    margin-top: 40px;
    position: relative;

    @include r($sm) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 0;
    }
}//show-all__wrap

.show-all {
    display: block;
    width: 100%;
    position: relative;

    &:before {
      background: url(../images/bg-lines-seamless.png) 0 0 repeat;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: opacity $anim ease;
      .desktop & {
        opacity: .7
      }
    }

    .show-all__inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        padding: 27px 20px;
        color: $white;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: .5px;
        font-weight: 500;
        position: relative;
    }
    .show-all__loading {
      display: none;
      height: 32px;
      width: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -16px 0 0 -16px;
      circle {
        fill: transparent;
        stroke-width: 5;
        stroke: $color-main;
      }
    }
    .desktop &:not(.disabled):hover {
        color: #fff;
        &:before {
          opacity: 1;
        }
    }
    &.disabled {
        cursor: default;
        &:before {
          opacity: .7;
        }
        .show-all__inner span {
          opacity: 0
        }
        .show-all__loading {
            display: block;
        }
    }
    @include r($xl) {
        .show-all__inner {
            padding: 21px 20px;
            font-size: 16px;
            line-height: 26px;
        }
    }
    @include r($sm) {
        order: 10;
    }
}//show-all

.bar-block {
    display: block;
    position:absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    width:100%;
    height:2px;
    background:none;
    overflow:hidden;
    opacity: 0;
    visibility: hidden;
    transition: all $anim ease;

    &.visible {
        opacity: 1;
        visibility: visible;
        .bar:before {
          animation:box-1 2100ms cubic-bezier(0.65,0.81,0.73,0.4) infinite;
        }
        .bar:after {
          animation:box-2 2100ms cubic-bezier(0.16,0.84,0.44,1) infinite;
          animation-delay:1150ms;
        }
    }

    .bar:before {
        content:"";
        position:absolute;
        top:0px;
        left:0px;
        bottom:0px;
        background:$color-main;
    }
    .bar:after {
        content:"";
        position:absolute;
        top:0px;
        left:0px;
        bottom:0px;
        background:$color-main;
    }

    @keyframes box-1 {
        0% {
            left:-35%;
            right:100%;
        }
        60%,100% {
            left:100%;
            right:-90%;
        }
    }
    @keyframes box-2 {
        0% {
            left:-200%;
            right:100%;
        }
        60%,100% {
            left:107%;
            right:-8%;
        }
    }
}

canvas {
    display: block;
}

.bg-contain {
    background-size: contain!important;
    background-position: 50% 50%!important;
}
