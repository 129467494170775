.download-button {
    margin-bottom: 60px;
    margin-top: 60px;
    @include r($sm) {
        margin-bottom: 30px;
        margin-top: 40px;
    }
}

.small-link {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $white;
}
.link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    .small-icon {
        display: block;
        height: 18px;
        width: 18px;
        margin-right: 10px;
        position: relative;
        overflow: visible;
        fill: $white;
    }
}
.desktop {
    .link-icon {
        transition: all $anim ease;
        .small-icon {
            transition: fill $anim ease;
        }
        &:hover {
            color: $color-main;
            .small-icon {
                fill: $color-main;
            }
        }
    }
}
.download-premium-cta {
    padding-bottom: 60px;
    @include r($sm) {
        padding-bottom: 40px;
    }
}
