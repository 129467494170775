footer {
  background: $base-bg;
}

.footer-middle,
.footer-top {
  width: 100%;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: $separator-color;
  }
}

.footer-top {
  display: flex;
  flex-direction: row;
  padding: 45px 0 40px;

  @include r($sm) {
    padding: 30px 0;
  }
}

.footer-middle {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  padding: 30px 0;

  @include r($lg) {
    align-items: flex-start;
  }
  @include r($table-screen) {
    flex-wrap: wrap;
  }
}
.footer-lang,
.partners-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 10;
  @include r($lg) {
    width: 53%;
  }
}

.footer-bottom {
  display: block;
  width: 100%;
  padding: 20px 0;
}

.footer-menu {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
  flex-grow: 30;

  li {
    display: block;
    list-style: none;
    margin-right: 60px;

    &:last-child {
      margin-right: 0;
    }
  }
  a {
    font-size: 12px;
    line-height: 14px;
    color: $grey;

    .desktop &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
  @include r($xl) {
    li {
      margin-right: 40px;
    }
  }
  @include r($lg) {
    li {
      width: 100%;
      text-align: center;
      margin-right: 0;
    }
  }
  @include r($table-screen) {
    justify-content: center;
    li {
      width: auto;
      margin-right: 40px;
      margin-bottom: 0;
    }
  }
  @include r($sm) {
    padding-left: 0;
    padding-right: 0;
    li {
      width: 100%;
      text-align: center;
      margin-right: 0;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}//footer-menu

.copyright {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  color: $grey;
  opacity: .5;
  p {
    display: inline;
  }
}

.lang-menu {
  display: block;
  width: 140px;

  img {
    display: block;
    width: 100%;
  }
}

.partners-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  a {
    display: block;
    height: 28px;
    margin-right: 40px;
	&:last-child {
      margin-right: 0;
	}

    img {
      display: block;
      height: 100%;
    }
  }
  @include r($lg) {
    a {
      margin-bottom: 10px;
	}
  }
  @include r($table-screen) {
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 -20px 40px;

    a {
      margin: 0 20px;
      &:last-child {
        margin-right: 20px;
      }
    }
  }
  @include r($xs) {
    a {
      img {
        height: 24px;
      }
    }
  }
}

.footer-social {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  .footer-social__title {
    margin-right: 80px;
    white-space: nowrap;
    padding: 2px 0;
    color: $grey;
    margin-bottom: 0;
  }
  .footer-social__list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: space-between;

    li {
      display: block;
      list-style: none;
      padding: 0 20px;

      &:last-child {
        margin-right: 0;
      }
    }
    a {
      display: block;
      width: 30px;
      height: 30px;
      @include r($xl) {
        width: 25px;
        height: 25px;
      }

      .icon {
        fill: $grey;
        transition: fill $anim ease;
      }

      .desktop &:hover {
        .icon {
          fill: $color-main;
        }
      }
    }
  }//footer-social__list

  @include r($table-screen) {
    flex-wrap: wrap;
    .footer-social__title {
      width: 100%;
      margin-right: 0;
      margin-bottom: 40px;
      text-align: center;
    }
  }
  @include r($sm) {
    flex-wrap: wrap;
    .footer-social__title {
      margin-bottom: 25px;
    }
    .footer-social__list {
      justify-content: space-around;
      li {
        flex-basis: 25%;
        //width: 25%;
        padding: 15px 0;
        text-align: center;

        a {
          margin: auto;
        }
      }
    }
  }
}//footer-social

.footer-lang {
    align-items: center;
    justify-content: flex-end;
    @include r($table-screen) {
        margin-top: 30px;
        justify-content: center;
    }
}
.lang-toggle {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: normal;
    color: $grey;
    padding: 7px 23px 7px 10px;
    transition: color $anim ease;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        right: 0;
        top: 50%;
        transform: rotate(45deg);
        margin-top: -5px;
        border-bottom: 1px solid $grey;
        border-right: 1px solid $grey;
        transition: all $anim ease;
    }
    img {
        display: block;
        margin-right: 10px;
        margin-top: 2px;
    }
    @include r($table-screen) {
        padding-right: 33px;
        &:after {
            right: 10px;
        }
    }
}
.footer-lang.active {
    .lang-toggle {
        color: $white;
        &:after {
            border-bottom-color: $white;
            border-right-color: $white;
            transform: rotate(45deg) scale(-1);
            margin-top: -1px;
        }
    }
    .lang-menu {
        opacity: 1;
        pointer-events: all;
        transform: translate3d(0, 0, 0);
    }
	@include r($table-screen) {
		.lang-menu-content {
			transform: translate3d(0, 0, 0);
		}
	}
}
.desktop {
    .lang-toggle {
        &:hover {
            color: $white;
            &:after {
                border-bottom-color: $white;
                border-right-color: $white;
            }
        }
    }
}
