.fullpage {
    background-color: $dark;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 0;
    min-height: 100vh;
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 66%;
        height: 100%;
        background-image: linear-gradient(to left, rgba(19, 20, 20, 0), #131414);
    }
    &-content {
        color: $white;
        padding: 0 56px;
        position: relative;
        z-index: 1;
        width: 100%;
    }
    &-logo {
        margin-bottom: 60px;
    }
    &-huge {
        font-size: 120px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.83;
        letter-spacing: 1.76px;
        margin-bottom: 40px;
    }
    h1 {
        margin-bottom: 0;
        + .fullpage-button {
            margin-top: 60px;
        }
    }
    h2 {
        max-width: 800px;
        margin-bottom: 0;
        + .fullpage-button {
            margin-top: 60px;
        }
    }
    @include r($xl) {
        &-logo {
            margin-bottom: 45px;
        }
        &-huge {
            margin-bottom: 30px;
        }
        h1, h2 {
            + .fullpage-button {
                margin-top: 45px;
            }
        }
        h2 {
            max-width: 640px;
        }
    }
    @include r($lg) {
        &-logo {
            img {
                max-height: 90px;
                width: auto;
            }
        }
        &-huge {
            font-size: 84px;
        }
    }
    @include r($sm) {
        h1 {
            margin-left: auto;
            margin-right: auto;
        }
        &:after {
            background-image: none;
            background-color: rgba($dark, .4);
            width: 100%;
        }
        &-content {
            padding: 0 20px;
            text-align: center;
        }
    }
}
