@font-face {
  font-family:'Caliber';
  src:url('../fonts/Caliber.eot');
  src:url('../fonts/Caliber.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Caliber.svg#Caliber') format('svg'),
  url('../fonts/Caliber.ttf') format('truetype'),
  url('../fonts/Caliber.woff') format('woff'),
  url('../fonts/Caliber.woff2') format('woff2');
  font-weight:normal;
  font-style:normal;
}
@font-face {
  font-family:'Caliber';
  src:url('../fonts/Caliber-Medium.eot');
  src:url('../fonts/Caliber-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Caliber-Medium.svg#Caliber-Medium') format('svg'),
  url('../fonts/Caliber-Medium.ttf') format('truetype'),
  url('../fonts/Caliber-Medium.woff') format('woff'),
  url('../fonts/Caliber-Medium.woff2') format('woff2');
  font-weight:500;
  font-style:normal;
}
@font-face {
  font-family:'Caliber';
  src:url('../fonts/Caliber-Bold.eot');
  src:url('../fonts/Caliber-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Caliber-Bold.svg#Caliber-Bold') format('svg'),
  url('../fonts/Caliber-Bold.ttf') format('truetype'),
  url('../fonts/Caliber-Bold.woff') format('woff'),
  url('../fonts/Caliber-Bold.woff2') format('woff2');
  font-weight:bold;
  font-style:normal;
}
