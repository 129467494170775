.title-patern {
  margin-bottom: 60px;
  padding:28px;
  width:100%;
  font-size:46px;
  line-height:1;
  font-weight:500;
  text-align:center;
  letter-spacing:5px;
  text-indent:5px;
  text-transform:uppercase;
  color:$color-main;
  background-image:url(../images/bg-lines-seamless.png);
  background-position:center center;
  background-repeat: repeat;
  @include r($xl) {
    padding:20px 25px;
    font-size:38px;
  }

  @include r($lg) {
    margin-bottom:6.5vh;
    padding:15px;
    font-size:30px;
  }

  @include r($md) {
    margin-bottom:40px;
    padding:12px 15px;
    font-size:24px;
    letter-spacing:6.77px;
  }

  @include r($xs) {
    margin-bottom:35px;
    display:inline-block;
    width:auto;
    width:560px;
    max-width: 100%;
    font-size:24px;
    letter-spacing:3.5px;
  }

  @include r($width_mob) {
    width:100%;
    min-width:0;
    font-size:20px;
    letter-spacing:3px;
    text-indent:3px;
    margin-bottom:30px;
  }
}
