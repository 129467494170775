.promo__wrap {
  display: block;
  width: 100%;
  margin: 0;
  max-width: 640px;
  &.size-lg {
    max-width: 780px;
  }

  &.dark {
    .promo__head__text {
      color: $main-black;
    }
  }

  .promo__head {
    display: block;
    padding: 0 0 35px;
  }

  .promo__head__img {
    display: block;
    margin: 0 auto 20px;
    width: 200px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      display: block;
      width: 100%;
    }
  }
  .promo__footer {
    padding: 20px 0 0;
  }
  .promo__footer__text {
    display: block;
    color: $grey;
    width: 100%;
    text-align: center;
  }
  .promo__head__text {
    display: block;
    width: 100%;
    text-align: center;
    color: $white;
    margin-bottom: 0;
  }
  @include r($xl) {
    max-width: 560px;
    .promo__head {
      padding-bottom: 25px;
    }
    .promo__head__img {
      width: 180px;
    }
    &.size-lg {
      max-width: 640px;
      .promo__head {
        padding-bottom: 35px;
      }
    }
  }//$xl
  @include r($lg) {
    max-width: 460px;
    &.size-lg {
      max-width: 460px;
    }
    .promo__head__img {
      width: 140px;
    }
  }
  @include r(1023px) {
    width: 100%;
    min-width: 280px;
    max-width: 460px;
    padding: 0;
    &.size-lg {
      max-width: 460px;
    }
  }//$xl
  @include r($md) {
    max-width: 380px;
    &.size-lg {
      max-width: 380px;
    }
  }
  @include r($sm) {
    .promo__head {
      padding-bottom: 20px;
    }
  }//$xl
}//promo__wrap

.promo {
  display: block;
  width: 100%;
  position: relative;

  .dark & {
    .promo__info {
      color: $dark-grey;
      background: none;
    }
    .promo__title {
      color: $main-black;
    }
    .promo__text {
      color: $dark-grey;
    }
  }
  .promo__top,
  .promo__bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 10px;
    position: relative;
  }
  .promo__top {
    .size-lg &,
    .banner-block__inner &,
    .section__content--front & {
      align-items: flex-start;
    }
  }

  .promo__content {
    display: block;

  }
  .promo__inside {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding-left: 17px;
    padding-right: 17px;

    .promo__line {
      display: block;
      flex: 1 0 auto;
      height: 1px;
      background: $grey;
      opacity: .3;
    }
  }
  .promo__angle {
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border: 2px solid $color-main;


    &.angle-top-left {
      left: 0;
      border-bottom: none;
      border-right: none;
      top: 14px;
      .size-lg &,
      .banner-block__inner &,
      .section__content--front & {
        top: 0;
      }
    }
    &.angle-top-right {
      left: auto;
      right: 0;
      border-left: none;
      border-bottom: none;
      top: 14px;
      .size-lg &,
      .banner-block__inner &,
      .section__content--front & {
        top: 0;
      }
    }
    &.angle-bottom-left {
      left: 0;
      border-top: none;
      border-right: none;
      top: 15px;
    }
    &.angle-bottom-right {
      left: auto;
      right: 0;
      border-top: none;
      border-left: none;
      top: 15px;
    }
  }
  .promo__info {
    display: block;
    color: $color-main;
    padding: 7px 13px;
    background: url(../images/bg-lines.png) 0 0 repeat;
    margin: 0 15px;
    min-width: 190px;
    text-align: center;
    @include r($xl) {
      min-width: 160px;
    }

    &.promo__info-coundown {
      background: none;
      color: $grey;
    }
  }
  .promo__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    text-align: center;

    .size-lg & {
      padding-left: 60px;
      padding-right: 60px;
    }

    &.promo__content-coundown {
      padding: 30px 25px;
    }
  }
  .promo__title {
    display: block;
    text-align: center;
    color: $white;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .promo__text {
    display: block;
    text-align: center;
    color: $grey;
    margin: 0 0 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  @include r($xl) {
    .promo__content {
      padding: 30px 10px;
      &.promo__content-coundown {
        padding: 20px 25px 15px;
      }
      .size-lg & {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
    .promo__info {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .promo__angle {
      &.angle-top-left,
      &.angle-top-right {
        top: 12px;
      }
      &.angle-bottom-left,
      &.angle-bottom-right {
        top: 12px;
      }
    }
  }
  @include r($lg) {
    .promo__content {
      .size-lg & {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
  @include r(1023px) {
    .promo__info {
      &.promo__info-coundown {
        margin: 0 5px;
      }
    }
  }
  @include r($sm) {
    .promo__content {
      padding: 20px 0;
      &.promo__content {
        padding-left: 0;
        padding-right: 0;
        .size-lg & {
          padding-left: 0;
          padding-right: 0;
        }
      }
      &.promo__content-coundown {
        padding: 10px 0px;
      }
    }
    .promo__info {
      &.promo__info-coundown {
        margin: 0;
      }
    }
  }//$xl
}//promo
