.main {
  flex-grow: 1;

  & > * {
    &:first-child {
      &:before {
        content: '';
        display: block;
        position: relative;
        width: 100%;
        height: 108px;
      }
    }
  }
}

.test-icon {
  display:block;
  width:3rem;
  height:3rem;
}
