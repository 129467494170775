.roles {
  width:100%;
  display:flex;
  flex-direction:column;

  @include r($xs) {
    margin-top:0;
  }
  &__list {
    flex-basis:100%;
    @media (max-width:1024px) {
      .html--device & {
        margin-top:30px;
      }
    }
  }
  .slick-list {
    overflow:visible;
  }
  .slick-list,
  .slick-track {
    height:100%;
  }
  &__item {
    & > .container {
      max-width:86%;
      height:100%;
      display:flex;
      @include r($md) {
        max-width:none;
      }
    }
    & > .container > .row {
      width:100%;
      flex-wrap:nowrap;

      @include r($md) {
        flex-direction:column-reverse;
        flex-wrap:wrap;
      }

      @media screen and (max-width:567px) {
        flex-direction:column;
      }
    }
  }
  &__right {
    position:relative;
    z-index:1;
    display:flex;
    justify-content:flex-end;

    @include r($md) {
      height:auto;
      justify-content: center;
    }

    @include r($xs) {
      position:relative;
      margin:0 auto 20px;
      height:auto;
      justify-content:center;
    }

    @include r($width_mob) {
      margin-right:-50px;
      margin-left:-50px;
    }
    .roles__sign {
      @include r($lg) {
        img {
          transform-origin:50% 50%;
        }
      }
      @include r($md) {
        img {
          transform-origin:100% 100%;
        }
      }
    }
    .roles__img {
      img {
        transform-origin:100% 50%;
      }
      @include r($lg) {
        img {
          transform-origin:50% 50%;
        }
      }
      @include r($md) {
        img {
          transform-origin:0% 100%;
        }
      }
    }
    img {
      max-height:35vw;
      transform:scale(1.2);
      @include r($lg) {
        transform:scale(1.1);
      }
      @include r($md) {
        max-height: 50vw;
        transform:scale(1.1);
      }
      @include r($md) {
      	height: 56vw;
        max-height: 500px;
      }
      @include r($width_mob) {
      	height: 70vw;
        transform:scale(1.15);
      }
    }
  }
  &__head {
    position:relative;
    text-align:center;

    @include r($xs) {
      position:relative;
      top:5px;
    }

    @include r($width_mob) {
      top:0;
    }
  }
  &__sign {
    position:absolute;
    z-index:-1;
    top:0;
    margin-left:-10%;
    right:10%;
    display:flex;
    height:100%;
    justify-content:flex-end;
    align-items:center;

    @include r($md) {
      visibility:visible;
    }
    @include r($xs) {
      margin-left:-5%;
      right:auto;
    }
  }
  &__img {
    @include r($md) {
      visibility:visible;
    }
    @include r($width_mob) {
      width:100%;
    }
  }
  &__text {
    margin-right:auto;
    margin-left:auto;
    max-width:445px;
    font-size:16px;
    font-weight:400;
    text-align:center;
    color:#9ea4ae;

    @include r($lg) {
      max-width:420px;
    }
    @include r($md) {
      max-width:528px;
      min-height:36px;
    }
    .text-block {
      color:#fefefe;

      @include r($lg) {
        font-size:13px;
        line-height:18px;
        letter-spacing:0;
        text-indent:0;
      }
    }
  }
  &__left {
    display:flex;
    width:100%;
    max-width:720px;
    flex-direction:column;
    justify-content:center;

    @include r($md) {
      margin-right:0;
      padding-left:0;
      visibility:visible;
      width:100%;
    }

    @include r($xs) {
      justify-content:flex-start;
      align-items:center;
    }

    @include r($width_mob) {
      padding-top:0;
    }
  }
  &__left-wrap {
    left:4.375%;
    @media only screen and (min-width: 568px) {
        order: 0;
    }
    @include r($lg) {
      left:0;
      padding-right:0 !important;
    }
    @include r($md) {
      flex: 0 0 auto;
      margin:-5% auto 0;
      width: 100%;
      max-width: 560px;
      position: relative;
      z-index: 2;
    }
    @include r($sm) {
      margin-top: -10px;
    }
    @include r($width_mob) {
      margin-top: -7.5%;
    }
  }
  &__right-wrap {
    @include r($md) {
      max-width: 80%;
    }
  }
  &__title {
    display:none;

    @include r($md) {
      margin-bottom:40px;
    }

    @include r($width_mob) {
      display:none;
    }
  }
  .slick-arrow {
    height:60px;
    position:absolute;
    top:50%;
    margin-top:-30px;
    flex-wrap:nowrap;
    width:50px;
    .arrow {
      box-sizing: border-box;
      border: solid 1px rgba($dark-grey, .6);
      background-color:rgba($dark, .6);
      display:flex;
      height:60px;
      width:50px;
      align-items:center;
      justify-content:center;
      transition:all $anim ease-in-out;
      svg {
        display: block;
        position: relative;
        overflow: visible;
      }
      path {
        fill:none;
        stroke:$white;
        stroke-width:2;
        transition:stroke $anim ease-in-out;
        stroke-linecap:square;
      }
    }
    .slick-arrow-label {
      box-sizing:border-box;
      background-color:rgba(#4b4e53, 0.8);
      position:absolute;
      left:50px;
      top:0;
      display:flex;
      align-items:center;
      justify-content:center;
      height:60px;
      width:0;
      overflow:hidden;
      transition:width 0.25s ease-in-out;
      color:#fff;
      font-size:14px;
      line-height:16px;
      letter-spacing:2px;
      text-indent:2px;
      text-transform:uppercase;
      font-weight:500;
      .html--device & {
        display:none;
      }
    }
    @include r($md) {
        display: block!important;
        top: 27.5%;
    }
    @include r($sm) {
    	top: 22.5%;
    }
    @include r($width_mob) {
      height:48px;
      margin-top:-24px;
      width:40px;
      top: 20%;
      .arrow {
        width: 40px;
        height: 48px;
      }
    }
    &:hover {
      .html--desktop & {
        .arrow {
          background-color:$color-main;
          border-color: $color-main;
          path {
            stroke:$black;
          }
        }
        .slick-arrow-label {
          width:160px;
        }
      }
    }
  }
  .slick-arrow--next {
    right:7%;
    .slick-arrow-label {
      left:auto;
      right:50px;
    }

    @include r($lg) {
      right:5%;
    }

    @include r($sm) {
      right: 20px;
    }
  }
  .slick-arrow--prev {
    left:7%;

    @include r($lg) {
      left:5%;
    }

    @include r($sm) {
      left: 20px;
    }
  }
}

.d-md-none {
    display: none;
    @include r($md) {
        display: block;
    }
}
