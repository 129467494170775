.mfp {
  &-wrap {
    &.hide-top {
      transition:transform 0.5s ease-in-out;
      transform:translate3d(0, -100%, 0);
    }
    &.hide-bottom {
      transition:transform 0.5s ease-in-out;
      transform:translate3d(0, 100%, 0);
    }
  }
  &-bg {
    &.hide-top {
      transition:transform 0.5s ease-in-out;
      transform:translate3d(0, -100%, 0);
    }
    &.hide-bottom {
      transition:transform 0.5s ease-in-out;
      transform:translate3d(0, 100%, 0);
    }
  }
  &-container {
    padding:0;
  }
  &-content {
    height:100%;
    .section {
      height:100%;
      @media (max-width:959px) {
        @media (orientation:landscape) {
          height:auto;
        }
      }
    }
  }
}

// CSS for FADE animation
.mfp-fade.mfp-bg {
  opacity:0;
  transition:all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity:0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity:0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity:0;
  transition:all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity:1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity:0;
}
