.visit-block {
  display: block;
  width: 100%;
  height: 0;
  position: relative;
  z-index: 100;
}
.fixed-mode + .main {
  .visit-controls {
    top: 150px - 37px;

    @include r($table-screen) {
      top: 110px;
    }
  }
}
.visit-controls {
  display: block;
  position: absolute;
  top: 140px;
  right: 20px;
  z-index: 50;
  transition: top $anim ease;

  @include r($table-screen) {
    top: 110px;
    right: 30px;
  }
  @include r($sm) {
    top: 100px;
    right: 20px;
  }
}
.visit-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 0;
  width: 40px;
  height: 40px;
  border: none;
  padding: 0;
  margin-top: -20px;
  background: none;
  outline: none;
  transition: all $anim ease;
  z-index: 50;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $dark;
    opacity: .6;
    transition: all $anim ease;
  }
  .icon {
    display: block;
    position: relative;
    width: 18px;
    height: 18px;
    fill: $white;
    margin: auto;
    transition: fill $anim ease;
  }
  &.active {
    transform: translate3d(0, 25vh, 0);
  }
  .desktop &:hover {
    .icon {
      fill: $main-black;
    }
    &:after,
    &:before {
      border-color: $color-main;
      background-color: $color-main;
      opacity: 1;
    }
    &:after {
      background: none;
    }
  }
}

.visit-banner {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: all $anim ease;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, -25%, 0);
  z-index: 51;

  &.visible {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  .visit-banner__bg {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    video {
      display: none;
      position: absolute;
      height: 100%;
      width: 177.77777778vh; /* 100 * 16 / 9 */
      min-width: 100%;
      min-height: 56.25vw; /* 100 * 9 / 16 */
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
  .visit-banner__close {
    display: block;
    position: absolute;
    top: 130px;
    right: 30px;
    height: 42px;
    width: 42px;
    z-index: 51;
    svg {
      display: block;
      position: absolute;
      overflow: visible;
      &.icon {
        height: 10px;
        width: 10px;
        margin: 16px;
        stroke: $grey;
        path {
          stroke-width: 2;
        }
      }
      circle {
        fill: transparent;
        stroke: rgba($grey, .3);
        stroke-width: 1;
      }
      .fill-circle {
        fill: transparent;
        stroke: $color-main;
        stroke-width: 3;
      }
    }
    @include r($xl) {
      top: 120px;
      right: 20px;
    }
    @include r($table-screen) {
      top: 90px;
      right: 30px;
    }
    @include r($sm) {
      top: 80px;
      right: 20px;
    }
  }
  .visit-banner__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 150px 0 50px;

    &.align-content-center {
      justify-content: center;
    }
    &.align-content-left {
      justify-content: flex-start;
    }
    &.align-content-right {
      justify-content: flex-end;
    }
    @include r($table-screen) {
      .promo__head {
        display: none;
      }
    }
    @media only screen and (max-width: 767px) and (orientation: landscape) {
      padding: 80px 0 20px;
      .promo__wrap {
        max-width: 440px;
      }
      .promo__title {
        margin-bottom: 0;
      }
      .promo__text {
        display: none;
      }
    }
  }
}

.desktop {
  .visit-banner {
    .visit-banner__close {
      svg.icon {
        transition: stroke $anim ease;
      }
      &:hover {
        svg.icon {
          stroke: $hover-color;
        }
      }
    }
    .visit-banner__bg {
      video {
        display: block;
      }
    }
  }
}
