.countdown {
    display: block;
    width: 600px;
    //height: 100px;
}
.countdown__wrap {
    display: block;
    overflow: hidden;
    width: 600px;

    @include r($xl) {
        min-width: 600px;
    }
    @include r($lg) {
        width: 460px;
        min-width: 460px;
    }
    @include r($md) {
      width: 300px;
	  min-width: 300px;
    }
    @include r(374px) {
        width: 140px;
        min-width: 140px;
    }
}

/**
 *	This element is created inside your target element
 *	It is used so that your own element will not need to be altered
 **/
.time_circles {
    position: relative;
    width: 100%;
    height: 100%;
}

/**
 *	This is all the elements used to house all text used
 * in time circles
 **/
.time_circles > div {
    position: absolute;
    text-align: center;
}

/**
 *	Titles (Days, Hours, etc)
 **/
.time_circles > div > h4 {
    font-family: $caliber;
    color: $grey;
    margin: 0px;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px!important;
    line-height: 1.66!important;
    letter-spacing: 0.42px;

    &:before {
        display: none;
    }

    .dark & {
        color: $dark-grey;
    }
}

/**
 *	Time numbers, ie: 12
 **/

.time_circles > div > span {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 34px!important;
    letter-spacing: .5px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35!important;
    text-transform: uppercase;
    color: $white;
    font-weight: 500;
    position: relative;
    margin-top: -12px;

    .dark & {
        color: $main-black;
    }
}
.time_circles > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    h4 {
        order: 5;
    }
}
