.oldbrowser-screen {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99999;
}

.oldbrowser {
    .header-main,
    .main,
    .footer {
        display: none;
    }
    .oldbrowser-screen {
        display: flex;
    }
}
