header {
  display: block;
  //background: $dark;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 250;
  transition: transform $anim ease;

  &.fixed-mode {
    @include rmin($table-screen) {
      transform: translate3d(0, -37px, 0);
    }
  }
}

.header-top {
  display: block;
  width: 100%;
  height: 37px;
  background: #000;
  position: relative;

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: $dark-grey;
    opacity: .7;
    transition: opacity $anim ease;
  }
  &:before {
    opacity: 0;
  }
  @include r($table-screen) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    //transform: translate3d(0,-100%,0);
    visibility: hidden;
    transition: all $anim ease;

    &:before {
      top: 0;
      opacity: .7;
    }

    &.visible {
      opacity: 1;
      transform: translate3d(0,0,0);
      visibility: visible;
    }
  }
}
.header-main {
  display: block;
  width: 100%;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $black;
    opacity: .85;
  }
}

.header-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  min-height: 60px;
}


.logo {
  display: block;
  width: 160px;
  min-width: 160px;
  min-height: 30px;
  margin-right: 60px;

  img {
    display: block;
    width: 100%;
  }
  @include r($table-screen) {
    margin: 0 auto;
  }
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;

  & > li {
    display: block;
    margin-right: 40px;
    list-style: none;
    position: relative;

    .desktop &:hover {
      ul {
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
      }
    }

    & > a {
      display: block;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      position: relative;
      color: $grey;
      padding-right: 18px;
      padding-top: 25px;
      padding-bottom: 26px;
      transition: color $anim ease;

      @include rmin($table-screen) {
        &.activated + ul {
          opacity: 1;
          visibility: visible;
          transform: translate(0, 0);
        }
      }

      &:last-child {
        padding-right: 0;

        &:before {
          display: none;
        }
      }


      &:not(.active).activated,
      .desktop &:not(.active):hover {
        color: $color-main;

        &:before {
          border-color: $color-main;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        right: 0;
        top: 50%;
        transform: rotate(45deg);
        margin-top: -5px;
        border-bottom: 1px solid $grey;
        border-right: 1px solid $grey;
        transition: border-color $anim ease;
        @include r($table-screen) {
          margin-top: -4px;
        }
      }
      &:after {
        content: '';
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background: $color-main;
      }
      &.active {
        color: $white;
        cursor: default;

        &:after {
          display: block;
        }
      }
    }
  }

  ul {
    @include rmin($table-screen) {
      display: block !important;
    }
    display: block;
    position: absolute;
    top: 100%;
    left: -7px;
    padding: 25px 0;
    margin: 0;
    background: $main-black;
    min-width: 280px;
    opacity: 0;
    visibility: hidden;
    transition: all $anim ease;
    transform: translate(0, 30px);

    & > li {
      display: block;
      list-style: none;
      padding: 0 0;
      margin: 0 0 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      display: block;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: 0.35px;
      position: relative;
      transition: color $anim ease;
      padding: 0 30px;

      .desktop &:not(.active):hover {
        color: $color-main;
      }
      &:after {
        content: '';
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 2px;
        background: $color-main;
      }
      &.active {
        color: $white;
        cursor: default;

        &:after {
          display: block;
        }
      }
    }
  }

  @include r($table-screen) {
    display: block;
    background: $dark;

    & > li {
      margin-right: 0;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: $dark-grey;
        opacity: .3;
      }

      & > a {
        display: block;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        position: relative;
        color: $grey;
        padding: 20px;

        &:last-child {
          padding-right: 0;

          &:before {
            display: none;
          }
        }


        .desktop &:not(.active):hover {
          color: $color-main;

          &:before {
            border-color: $color-main;
          }
        }

        &:before {
          //display: none;
          right: 20px;
        }
        &:after {
          display: none;
        }
        &.active {
          color: $white;
          cursor: default;

          &:after {
            display: none;
          }
        }
      }
    }

    ul {
      display: none;
      position: relative;
      top: auto;
      left: 0;
      padding: 25px 0;
      margin: 0;
      background: $main-black;
      min-width: 260px;
      opacity: 1;
      visibility: visible;
      transition: none;
      transform: translate(0);

      & > li {
        display: block;
        list-style: none;
        padding: 0 0;
        margin: 0 0 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
      a {
        display: block;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: 0.35px;
        position: relative;
        transition: color $anim ease;
        padding: 0 30px;

        .desktop &:not(.active):hover {
          color: $color-main;
        }
        &:after {
          content: '';
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 2px;
          background: $color-main;
        }
        &.active {
          color: $white;
          cursor: default;

          &:after {
            display: block;
          }
        }
      }
    }
  }//$table-screen
}//menu

.header-toggle {
  display: none;
  position: absolute;
  top: 50%;
  left: -10px;
  width: 44px;
  height: 44px;
  margin-top: -22px;

  @include r($table-screen) {
    display: block;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 24px;
    height: 2px;
    background: $grey;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    transition: all $anim ease;
  }
  &:before {
    margin-top: -5px;
  }
  &:after {
    margin-top: 3px;
  }
  &.activated {
    &:before {
      transform: rotate(135deg);
      margin-top: -1px;
    }
    &:after {
      margin-top: -1px;
      transform: rotate(-135deg);
    }
  }
}

.header-content {
  display: block;

  @include r($table-screen) {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    height: 100vh;
    width: 100vw;
    margin-left: -50vw;
    bottom: 0;
    z-index: 50;
    padding-left: 60px;
    transform: translate3d(-100%,0,0);
    transition: transform $anim ease;

    &.visible {
      transform: translate3d(0,0,0);
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 60px;
      left: 0;
      bottom: 0;
      background: $black;
      opacity: .95;
    }

    .header-content__inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      overflow: auto;
      background: $header-menu-bg;
    }
  }
  .close-menu {
    display: none;
    position: absolute;
    top: 12px;
    left: 10px;
    width: 44px;
    height: 44px;

    @include r($table-screen) {
      display: block;
    }

    .icon {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 22px;
      height: 22px;
      margin-top: -11px;
      margin-left: -11px;
      transition: all $anim ease;
      fill: $grey;
    }
    .menu {
      display: none;
    }
  }//$table-screen
}//header-content

.header-download-link {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);

  @include r($table-screen) {
    position: static;
    transform: none;
    width: 100%;
    margin-top: auto;
    padding: 30px;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      width: 50%;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @include r($xs) {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    a {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      &:nth-child(2) {
        margin-top: 20px;
      }
    }
  }
}

.secondary-link {
	box-sizing: border-box;
	display: inline-block;
    padding: 11px 27px;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid rgba($grey, .5);
    background: transparent;
	color: $white;
    font-weight: 500;
    text-transform: uppercase;
    height: 40px;
    letter-spacing: .5px;
	margin-right: 20px;
    transition: all $anim ease;
    text-align: center;

    .desktop &:hover {
      background: $color-main;
	  border-color: $color-main;
	  color: $main-black;
    }
    @include r($table-screen) {
      margin-bottom: 20px;
      width: 100%;
      display: block;
    }
  }

.download-link {
  display: inline-block;
  padding: 12px 28px;
  font-size: 14px;
  line-height: 16px;
  color: $main-black;
  background: $color-main;
  font-weight: 500;
  text-transform: uppercase;
  height: 40px;
  letter-spacing: .5px;
  transition: all $anim ease;
  text-align: center;

  .desktop &:hover {
    color: $main-black;
    background: $hover-color;
  }
  @include r($table-screen) {
    width: 100%;
    display: block;
  }
}


.top-toggle {
  display: none;
  position: absolute;
  top: 50%;
  right: -10px;
  left: auto;
  width: 44px;
  height: 44px;
  margin-top: -22px;

  .tt_icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 22px;
    margin-top: -11px;
    margin-left: -11px;
    transition: all $anim ease;

    &.less {
      opacity: 0;
      transform: scale(0,0);
    }
  }
  .icon {
    fill: $grey;
  }

  &.activated {
    .tt_icon {
      &.less {
        opacity: 1;
        transform: scale(1);
      }
      &.more {
        opacity: 0;
        transform: scale(0);
      }
    }
  }

  @include r($table-screen) {
    display: block;
  }
}
