//$boostrap-src: '../../libs/bootstrap/scss/';

//media vars
$xs: 479px;
$sm: 767px;
$md: 992px;
$lg: 1200px;
$xl: 1536px;
$xxl: 100%;

@import "../../libs/bootstrap/scss/bootstrap-reboot";
@import "../../libs/bootstrap/scss/variables";

$grid-breakpoints: (
  xs: 0,
  sm: $xs,
  md: $sm,
  lg: $md,
  xl: $lg,
  xxl: $xl
);
@import "../../libs/bootstrap/scss/mixins";
@import "../../libs/bootstrap/scss/grid";




//margins media
$margin-xs: 20px;
$margin-sm: 20px;
$margin-md: 30px;
$margin-lg: 30px;
$margin-xl: 30px;
$margin-xxl: 56px;

//guttenr column
$gutter-xs: 16px;
$gutter-sm: 16px;
$gutter-md: 30px;
$gutter-lg: 30px;
$gutter-xl: 30px;
$gutter-xxl: 40px;

//container max width
$container-width-xs: 1328px;
$container-width-sm: 1328px;
$container-width-md: 1328px;
$container-width-lg: 1328px;
$container-width-xl: 1328px;
$container-width-xxl: 1600px;


$grid-gutter-width: 30px;
$grid-gutter-width-sm: 16px;
$grid-gutter-width-xl: 40px;


@include r($sm) {
  .row:not(.no-gutters) {
    margin-left: -$grid-gutter-width-sm/2;
    margin-right: -$grid-gutter-width-sm/2;
  }
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto, .col-xxl, .col-xxl-1, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-auto {
    padding-left: $grid-gutter-width-sm/2;
    padding-right: $grid-gutter-width-sm/2;
  }

}
@include rmin($xl) {
  .row {
    margin-left: -$grid-gutter-width-xl/2;
    margin-right: -$grid-gutter-width-xl/2;
  }
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto, .col-xxl, .col-xxl-1, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-auto {
    padding-left: $grid-gutter-width-xl/2;
    padding-right: $grid-gutter-width-xl/2;
  }

}



@mixin containerSizes($max-width, $padding) {
  max-width: $max-width + (2*$padding);
  padding:0 $padding;
}



.container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position:relative;
  @include containerSizes($container-width-xxl,$margin-xxl);

  //& > * {
  //  &:last-child {
  //    margin-bottom: 0;
  //  }
  //}

  &.container-lg {
    max-width: 1920px;
  }
  &.container-sm {
    @include rmin($lg) {
      @include containerSizes(1055px,$margin-xxl);
    }
  }

  @include r($xl) {
    @include containerSizes($container-width-xl,$margin-xl);
  }
  @include r($lg) {
    @include containerSizes($container-width-lg,$margin-lg);
  }
  @include r($md) {
    @include containerSizes($container-width-md,$margin-md);
  }
  @include r($sm) {
    @include containerSizes($container-width-sm,$margin-sm);
  }
  @include r($xs) {
    @include containerSizes($container-width-xs,$margin-xs);
  }
}//container
