.logo {
  display: inline-block;
  max-width: 311px;
  max-height: 173px;

  @include media-breakpoint-down(lg) {
    max-width: 217px;
    max-height: 121px;
  }

  @include media-breakpoint-down(sm) {
    max-width: 190px;
    max-height: 105px;
  }

  img {
    display: block;
  }
}
