.tabs,
.tabs_block {
    display: block;
    width: 100%;
    margin: 60px 0;
    //overflow: hidden;
    &:first-child {
        margin-top: 0;
    }

    .th__list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
            background-image: linear-gradient(to right, rgba($grey, .3) 33.333%, rgba(255, 255, 255, 0) 0%);
            background-position: top left;
            background-size: 6px 2px;
            background-repeat: repeat-x;
        }

        & > li {
            display: block;
            list-style: none;
            margin-right: 40px;

            &.hide {
                display: none;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        a {
            display: block;
            font-size: 16px;
            line-height: 26px;
            font-weight: 500;
            color: $grey;
            text-transform: uppercase;
            position: relative;
            padding: 0 0 20px;
            white-space: nowrap;
            text-decoration: none;

            &:after {
                content: '';
                display: none;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 2px;
                background: $color-main;
            }

            &.active {
                cursor: default;
                color: $white;

                &:after {
                    display: block;
                }
            }

            .desktop &:not(.active):hover {
                color: $color-main;
            }
        }
    }
    //

    .tabs_buttons {
        display: none;
    }

    .tabs__head {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0 0 40px;
        width: 100%;
        position: relative;
        align-items: flex-end;
        justify-content: flex-start;
    }

    .tabs_names {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        position: relative;
        width: 100%;
        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
            background-image: linear-gradient(to right, rgba($grey, .3) 33.333%, rgba(255, 255, 255, 0) 0%);
            background-position: top left;
            background-size: 6px 2px;
            background-repeat: repeat-x;
        }
    }

    .tabs_name {
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        color: $grey;
        text-transform: uppercase;
        position: relative;
        padding: 0 0 20px;
        white-space: nowrap;
        text-decoration: none;
        margin-right: 40px;
        cursor: pointer;

        &.hide {
            display: none;
        }

        &:last-child {
            margin-right: 0;
        }

        &:after {
            content: '';
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
            background: $color-main;
        }

        &.active {
            cursor: default;
            color: $white;

            &:after {
                display: block;
            }
        }

        .desktop &:not(.active):hover {
            color: $color-main;
        }
    }

    .th__main {
        display: block;
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .th__more {
        display: block;
        width: 80px;
        min-height: 100%;
        //position: relative;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
    }

    .tabs__toggle {
        display: block;
        position: absolute;
        right: 0;
        width: 24px;
        height: 24px;
        transform: translate3d(0,0,0);

        .toggle-icon {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 2px;
            transition: all $anim ease;
        }

        .close-icon {
            visibility: hidden;
            opacity: 0;
            transform: scale(0);
        }

        .icon {
            fill: $grey;
            transition: fill $anim ease;
        }

        &.activated {
            .open-icon {
                visibility: hidden;
                opacity: 0;
                transform: scale(0);
            }

            .close-icon {
                visibility: visible;
                opacity: 1;
                transform: scale(1);
            }
        }

        .desktop &:hover {
            .icon {
                fill: $color-main;
            }
        }
    }

    .th__drop {
        display: block;
        position: absolute;
        top: 100%;
        left: auto;
        right: 0;
        padding: 17.5px 0;
        margin: 0;
        background: $main-black;
        min-width: 280px;
        transition: all $anim ease;
        opacity: 0;
        visibility: hidden;
        transform: translate(0, 30px);

        &.visible {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
        }

        & > li {
            display: block;
            list-style: none;
            padding: 7.5px 0;

            &.hide {
                display: none;
            }
        }

        a {
            display: block;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            letter-spacing: 0.35px;
            position: relative;
            transition: color $anim ease;
            padding: 0 30px;
            text-decoration: none;

            .desktop &:not(.active):hover {
                color: $color-main;
            }

            &:after {
                content: '';
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 2px;
                background: $color-main;
            }

            &.active {
                color: $white;
                cursor: default;

                &:after {
                    display: block;
                }
            }
        }
    }

    .tab-one {
        display: none;

        &.visible {
            display: block;
        }
    }

    .tabs_text {
        display: none;
        p, ul, ol {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }

        &.active {
            display: block;
        }
    }
    @include r($xl) {
        margin-bottom: 40px;
    }
    @include r($md) {
        margin-top: 40px;
        .tabs__head {
            margin-bottom: 30px;
        }
    }
    @include r($sm) {
        margin-bottom: 30px;
    }
}
//tabs
