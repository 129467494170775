.popup-wrapper {
	background-color: rgba($black, .8);
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 88888;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	transition: opacity $anim ease-in-out;
}
.popup-window {
	background: $separator-color;
	color: $base-color;
	width: 100%;
	max-width: 780px;
	margin: 0 auto;
	position: relative;
	opacity: 0;
	transform: scale(1.05);
	transition: all $anim ease-in-out;
}
.popup-wrapper.visible {
	opacity: 1;
	pointer-events: all;
	.popup-window {
		transform: scale(1);
		opacity: 1;
	}
}
.popup-close {
	display: block;
	padding: 15px;
	position: absolute;
	top: 0;
	right: 0;
	height: 40px;
	width: 40px;
	z-index: 1;
	svg {
		display: block;
		path {
			fill: transparent;
			stroke: $grey;
			stroke-width: 2;
		}
	}
}
.popup-header {
	background-image: url(../images/lang-popup-header.jpg);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 50px 60px;
	position: relative;
	&:before {
		background: rgba($black, .3);
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	img {
		max-width: 100%;
		width: auto;
		height: auto;
		display: block;
		margin: 0 auto;
		position: relative;
		z-index: 1;
	}
}
.popup-body {
	padding: 40px 40px 0;
	text-align: center;
	.popup-text {
		padding: 0 20px;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.56;
		letter-spacing: 0.5px;
	}
	h5 {
		color: $color-main;
		margin-bottom: 10px;
	}
	.popup-lead {
		text-transform: uppercase;
		margin-bottom: 20px;
  		font-size: 12px;
  		font-weight: 500;
  		font-stretch: normal;
  		font-style: normal;
  		line-height: 2.17;
  		letter-spacing: 0.5px;
  		color: $white;
	}
	.btn-default {
		display: block;
		margin-top: 30px;
		margin-bottom: 35px;
	}
}
.popup-footer {
	border-top: 1px solid $dark-grey;
	padding: 30px 20px;
	&-link {
		color: $base-color;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.63;
		letter-spacing: normal;
		text-align: center;
	}
}
@include r($xl) {
	.popup-window {
		max-width:768px;
	}
	.popup-header {
		img {
			max-height: 112px;
		}
	}
	.popup-body {
		.popup-text {
			font-size: 16px;
		}
	}
}
@include r($sm) {
	.popup-wrapper {
		display: block;
		position: absolute;
		overflow: auto;
	}
	.popup-window {
		min-height: 100vh;
	}
	.popup-close {
		position: fixed;
		z-index: 88889;
	}
	.popup-content {
		min-height: 100vh;
	}
	.popup-header {
		padding: 30px 20px;
		width: 100%;
		height: 144px;
		img {
			height: 84px;
		}
	}
	.popup-body {
		padding-top: 30px;
		padding-left: 20px;
		padding-right: 20px;
		min-height: calc(100vh - 144px);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.popup-text {
			padding: 0;
			font-size: 14px;
		}
		.popup-lead {
			margin-bottom: 15px;
		}
		.btn-default {
			margin-bottom: 30px;
			margin-top: 15px;
		}
	}
	.popup-footer {
		padding: 30px 0;
		width: 100%;
		&-link {
			font-size: 14px;
		}
	}
}
@media only screen and (max-width: 992px) and (orientation: landscape) {
	.popup-footer {
		padding-bottom: 70px;
	}
}
.desktop {
	.popup-close {
		svg {
			path {
				transition: stroke $anim ease-in-out;
			}
		}
		&:hover {
			svg {
				path {
					stroke: $hover-color;
				}
			}
		}
	}
	.popup-footer-link {
		&:hover {
			color: $base-color;
			text-decoration: underline;
		}
	}
}

.lang-menu {
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 20px, 0);
    transition: all $anim ease;
    position: absolute;
	left: 0;
	width: 100%;
	bottom: 100%;
	z-index: 60;
	&-content {
		background: $separator-color;
		padding: 0 40px;
	}
	&-close {
		position: absolute;
		top: 0;
		right: 0;
		padding: 15px;
		height: 40px;
		width: 40px;
		z-index: 1;
		svg {
			display: block;
			path {
				fill: transparent;
				stroke: $grey;
				stroke-width: 2;
			}
		}
	}
	&-head {
		padding: 30px 0;
		text-align: center;
		border-bottom: 1px solid $dark-grey;
		position: relative;
		p {
			margin: 0;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			color: $grey;
		}
	}
	&-title {
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.63;
		letter-spacing: normal;
		text-align: center;
		color: $white;
		text-transform: uppercase;
		margin-bottom: 10px;
	}
	&-body {
		padding: 30px 0;
		position: relative;
	}
	&-region {
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.63;
		letter-spacing: normal;
		color: $white;
		text-transform: uppercase;
		margin-bottom: 15px;
	}
	&-list {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			display: block;
		}
		&__item {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 5px 0;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 19px;
			letter-spacing: normal;
			color: $base-color;
			text-transform: uppercase;
			img {
				display: block;
				width: 14px;
				height: 9px;
				margin-top: 4px;
				margin-right: 10px;
			}
			&.selected {
				color: $hover-color;
			}
		}
	}
	&.tiny {
		left: auto;
		width: 280px;
		.lang-menu-content {
			padding: 0 30px;
			width: 280px;
		}
		.lang-menu-head {
			text-align: left;
			border-bottom: none;
			padding-bottom: 0;
			padding-top: 25px;
		}
		.lang-menu-title {
			text-align: left;
		}
		.lang-menu-body {
			padding-top: 10px;
			padding-bottom: 20px;
		}
	}
	@include r($table-screen) {
		transform: translate3d(0, 0, 0);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1200;
		.col-lg-3 {
			flex: 0 0 100%;
			max-width: 100%;
			margin-top: 30px;
		}
		&.tiny {
			left: 0;
			width: auto;
		}
		&-content {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			transform: translate3d(280px, 0, 0);
			transition: transform $anim ease-in-out;
			opacity: 1;
			width: 280px;
			z-index: 1201;
			overflow: auto;
			padding-left: 20px;
			padding-right: 20px;
		}
		&:before {
			content: '';
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba($black, .8);
			z-index: 1200;
		}
		&-body {
			padding-top: 0;
		}
	}
}
@media only screen and (max-width: 992px) and (orientation: landscape) {
	.lang-menu-content {
		padding-bottom: 70px;
	}
}
.desktop {
	.lang-menu {
		&-close {
			svg {
				path {
					transition: stroke $anim ease-in-out;
				}
			}
			&:hover {
				svg {
					path {
						stroke: $hover-color;
					}
				}
			}
		}
		&-list {
			&__item {
				transition: color $anim ease-in-out;
				&:hover {
					color: $hover-color;
				}
			}
		}
	}
}
