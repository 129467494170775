.section-main {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}

.main-slider {
  display: block;
  position: relative;
  width: 100%;
  .slick-track
  {
    display: flex !important;
  }
  .slick-slide
  {
    height: inherit !important;
   }
  .slide-one {
    display: block;
    width: 100%;
    position: relative;
    padding-top: 150px;
    padding-bottom: 50px;
	height: 100%;
    .container {
      width: 90%;
    }
  }
  .slide-one__bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }
  .slide-one__bg-device {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: 50%;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, .4);
        .dark & {
            &:after {
              background: rgba(255, 255, 255, .3);
            }
        }
      }
  }
  .slide-one__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 570px;

    &.align-content-center {
      justify-content: center;
    }
    &.align-content-left {
      justify-content: flex-start;
    }
    &.align-content-right {
      justify-content: flex-end;
    }
  }
  .slick-arrow {
    top: calc(50% + 32px);
  }
  @include r($xl) {
    .slide-one {
      .container {
        width: 100%;
      }
    }
    .slide-one__inner {
      padding-left: 56px;
      padding-right: 56px;
    }
  }//$xl
  @include r($table-screen) {
    .slide-one {
      padding-top: 100px;
      padding-bottom: 70px;
      &.to-left {
        .slide-one__bg {
            background-position: 66.667% 50%;
        }
      }
      &.to-right {
          .slide-one__bg {
            background-position: 33.333% 50%;
          }
      }
    }
    .slide-one__inner {
      padding-left: 0;
      padding-right: 0;
      min-height: 400px;
    }
  }
  @include r(960px) {
      .slide-one {
        &.to-left {
          .slide-one__bg {
              background-position: 60% 50%;
          }
        }
        &.to-right {
            .slide-one__bg {
              background-position: 40% 50%;
            }
        }
      }
  }
  @include r(850px) {
      .slide-one {
        &.to-left {
          .slide-one__bg {
              background-position: 55% 50%;
          }
        }
        &.to-right {
            .slide-one__bg {
              background-position: 45% 50%;
            }
        }
      }
  }
  @include r($sm) {
    .slide-one__inner {
      min-height: 360px;
      &.align-content-center,
      &.align-content-left,
      &.align-content-right {
        justify-content: center;
      }
    }
    .slide-one__bg {
        display: none;
    }
    .slide-one__bg-device {
        display: block;
    }
  }//$xl
  @media only screen and (max-width: 767px) and (orientation: portrait) {
    .slide-one {
      padding-top: 130px;
    }
  }
}//main-slider


.section-default {
  display: block;
  width: 100%;
  padding: 60px 0 0;
  position: relative;

  &.section-default-dark {
    background: $dark;
    color: $white;
  }
}

.section-default-news {
  @include r($md) {
    padding-top: 40px;
  }
}

.section-default__bg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.section-other {
  display: block;
  width: 100%;
  position: relative;
  padding: 80px 0 40px;

  .section-other__bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @include r($md) {
    padding-top: 60px;
    padding-bottom: 20px;
    .news-list .row .col-lg-4 {
      &:nth-child(3) {
        display: none;
      }
    }
  }
  @include r($sm) {
    padding-top: 40px;
    padding-bottom: 10px;
  }
}
.section__title {
  display: block;
  position: relative;
  margin: 0 0 40px;
  color: $grey;
  @include r($xl) {
    margin-bottom: 30px;
  }
}

.section-head {
  display: block;
  width: 100%;
  position: relative;
  padding: 0;

  .main > & {
    &:before {
      display: none;
    }
  }

  .section-head__inner {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    position: relative;
    padding: 260px 0 80px;
    h1 {
      min-height: 201px;
    }
  }
  .section-head__img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }
  .container {
    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include r($xl) {
    .section-head__inner {
      padding: 250px 0 50px;
      h1 {
        min-height: 168px;
      }
    }
  }
  @include r($lg) {
    .section-head__inner {
      padding-top: 200px;
      h1 {
        min-height: 138px;
      }
    }
  }
  @include r($md) {
    .section-head__inner {
        padding-top: 150px;
        h1 {
          min-height: 105px;
        }
    }
  }
  @include r($sm) {
      .section-head__inner {
          padding-bottom: 40px;
          padding-top: 130px;
          h1 {
            min-height: 70px;
          }
      }
  }
}//section-head

.section-slider {
  display: block;
  width: 100%;
  padding: 80px 0 60px;
  position: relative;
  overflow: hidden;

  .section-slider__bg {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }
}

.slider-default {
  display: block;
  position: relative;
  padding: 0 20px;
  margin: 0 -40px;

  .slick-list {
    overflow: visible;
  }
  .slick-slide {
  }
  .slick-track {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
  }
  .slide-one {
    display: block;
    width: calc((100vw - 112px) * .33333333 + 13.333332px);
    max-width: calc(1700px * .33333333 - 20px);
    padding: 0 20px;
    height: auto;
  }
  .slide-one-lg {
    width: calc((100vw - 112px) * .66666666 + 26.666664px);
    max-width: calc(1700px * .66666666 - 40px);
    .news-one-lg {
      padding-bottom: 56.25%;
      min-height: 590px;
    }
    .news-one-lg__inner {
      position: absolute;
      width: 100%;
    }
  }

  .slick-list {
    .slide-one-lg {
      .news-one-lg {
        min-height: 0;
        padding-bottom: 0;
      }
      .news-one-lg__inner {
        min-height: 0;
      }
    }
  }
  .slick-dots {
    bottom: 0;
    position: relative;
    > li {
        margin-top: 35px;
        &:only-child {
            display: none;
        }
    }
    //bottom: -60px;
  }
  .slick-arrow {
    left: 50%;
    &.slick-prev {
      margin-left: -50vw;
      transform: translate(3vw, 0);
    }
    &.slick-next {
      left: auto;
      right: 50%;
      margin-right: -50vw;
      transform: translate(-3vw, 0);
    }
  }
  @include r($xl) {
    padding-left: 25px;
    padding-right: 25px;
    .slide-one {
      padding: 0 15px;
      width: calc((100vw - 60px) * .33333333 + 10px);
      max-width: calc(1418px * .33333333 - 20px);
    }
    .slide-one-lg {
      width: calc((100vw - 60px) * .66666666 + 20px);
      max-width: calc(1418px * .66666667 - 40px);
      .news-one-lg {
        padding-bottom: 56.25%;
        min-height: 59.3333333%;
      }
    }
    .slick-list {
     .slide-one-lg {
        .news-one-lg {
          min-height: 0;
          padding-bottom: 0;
        }
      }
    }
  }
  @include r($lg) {
    .slide-one {
      width: 360px;
      .news-one__inner {
        padding-bottom: 0;
      }
    }
    .slide-one-lg {
      width: 623px;
      .news-one-lg {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        min-height: 333.56px;
        padding-bottom: 0;
      }
      .news-one-lg__inner {
        height: auto;
        width: 100%;
        .nol__content {
          width: 300px;
        }
      }
    }
    .slick-list {
     .slide-one-lg {
        .news-one-lg {
          min-height: 0;
        }
      }
    }
  }
  @include r($sm) {
    padding-left: 20px;
    padding-right: 20px;
    .slide-one,
    .slide-one-lg {
      padding: 0 20px;
      width: 100vw;
      max-width: none;
      .news-one-lg {
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;
        &:before {
          background-image: linear-gradient(to right,rgba(158,164,174,.3) 33.333%,rgba(255,255,255,0) 0);
          background-position: top left;
          background-size: 6px 2px;
          background-repeat: repeat-x;
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
        }
        .news-one-lg__bg {
          position: relative;
          width: 100%;
          padding: 0 0 56.25%;
          height: auto;
        }
        .news-one-lg__inner {
          min-height: 0;
          padding: 0;
          display: block;
          padding-bottom: 32px;
          padding-right: 40px;
          position: relative;
          padding-top: 20px;
          flex: 1 0 auto;
          .nol__content {
            width: 80%;
            min-width: 280px;
          }
          .nol__title {
            font-size: 16px;
            line-height: 1.34;
            letter-spacing: .4px;
          }
        }
      }
    }
    .slide-one {
      .news-one__inner {
        padding-bottom: 32px;
      }
      .news-one__info {
        padding: 6px 10px;
        background: url(../images/bg-lines.png) 0 0 repeat;
        color: $color-main;
        margin: 0 0 10px;
        width: 80%;
        min-width: 280px;
      }
    }
  }
}

.slider-default {
  .slick-slide {
    opacity: .2;
    pointer-events: none;
    transition: opacity $anim ease-in-out;
  }
  .slick-current,
  .slick-current + .slick-slide,
  .slick-current:not(.slide-one-lg) + .slick-slide + .slick-slide {
    opacity: 1;
    pointer-events: all;
  }
  @include r($md) {
    .slick-current + .slick-slide,
    .slick-current:not(.slide-one-lg) + .slick-slide + .slick-slide {
      opacity: .2;
      pointer-events: none;
    }
    .slick-current:not(.slide-one-lg) + .slick-slide {
      opacity: 1;
      pointer-events: all;
    }
  }
}
