.dots {
  position:relative;
  margin-top:60px;

  @include r($lg) {
    margin-top:30px;
  }
  @include r($sm) {
    margin-top:45px;
  }

  @media (max-width:1024px) {
    .html--device & {
      margin-top:60px;
    }
  }
  @media (max-width:767px) {
    .html--device & {
      margin-top:40px;
    }
  }
  &:after {
    position:absolute;
    bottom:16px;
    left:0;
    display:block;
    width:100%;
    content:'';
    border-top:1px dashed #56585d;

    @include r($lg) {
      bottom:12.5px;
    }
  }
  &__img {
    position:absolute;
    top:-50px;
    margin-bottom:15px;
    margin-left:-12.5px;
    display:inline-block;
    width:25px;
    opacity:0;

    @include transition;

    @include r($lg) {
      display:none;
    }
    img {
      display:block;
      height:25px;
    }
  }
  &__title {
    position:absolute;
    top:-15px;
    left:50%;
    opacity:0;
    white-space:nowrap;
    transform:translateX(-50%);

    @include transition;

    @include r($lg) {
      top:-10px;
    }
  }
  &__hover {
    position:absolute;
    bottom:9px;
    left:50%;
    margin-left:-1.5px;
    display:block;
    width:3px;
    height:14px;
    content:'';
    background-color:#969ca5;
    cursor:pointer;

    @include transition;

    @include r($lg) {
      bottom:6.5px;
      height:13px;
    }
    &:after {
      position:absolute;
      top:50%;
      left:50%;
      margin-top:-8px;
      margin-left:-8px;
      display:block;
      width:16px;
      height:16px;
      opacity:0;
      content:'';
      background-color:#161717;
      border:4px solid #fff;
      border-radius:50%;

      @include transition;

      @include r($lg) {
        margin-top:-6.5px;
        margin-left:-6.5px;
        width:13px;
        height:13px;
      }
    }
    &:hover {
      background-color:#fff;
      ~ .dots__title {
        opacity:1;
      }
    }
  }
  &__item {
    position:relative;
    padding-bottom:40px;
    text-align:center;
    &.is-active {
      .dots__hover {
        bottom:0;
        margin-left:-16px;
        width:32px;
        height:32px;
        background-color:transparent;
        border:1px solid #56585d;
        border-radius:50%;

        @include r($lg) {
          margin-left:-12.5px;
          width:25px;
          height:25px;
        }
        &:after {
          opacity:1;
        }
      }
      .dots__img,
      .dots__title {
        opacity:1;
      }
    }
  }
  &__list {
    position:relative;
    z-index:1;
    display:flex;
    justify-content:space-between;
    &:after {
      position:absolute;
      z-index:-1;
      bottom:16px;
      left:0;
      display:block;
      width:100%;
      height:1px;
      content:'';
      background-color:#56585d;

      @include r($lg) {
        bottom:12.5px;
      }
    }
  }
}

.dots__list-wrap {
  min-width: 200px;
}
