.loader {
  position:fixed;
  z-index:2000;
  top:0;
  left:0;
  display:flex;
  width:100%;
  height:100%;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  background:$dark;
  background-size:auto 100%;

  @include r($md) {
    background:$dark;
  }
}
.loader__logo {
  margin-bottom:50px;
  animation-name:loaderRotate;
  animation-duration:1s;
  animation-timing-function:linear;
  animation-delay:0.5s;
  animation-iteration-count:infinite;
  img {
    display:block;
    max-width:100%;
    max-height:100%;
  }
}
.loader__text {
  font-size:16px;
  line-height:1;
  font-weight:bold;
  text-align:center;
  text-transform:uppercase;
  color:$grey;
}
.loader__box {
  display:flex;
  flex-direction:column;
  align-items:center;
  animation-name:loaderFade;
  animation-duration:0.5s;
  animation-timing-function:linear;
  animation-fill-mode:both;
}

@keyframes loaderFade {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}

@keyframes loaderRotate {
  from {
    transform:rotate(0deg);
    transform-origin:center center;
  }
  to {
    transform:rotate(360deg);
    transform-origin:center center;
  }
}
