.table_new {
  display: flex;
  width: 100%;
  font-size: 0;
  margin: 0 0 60px;

  .table_column {
    flex: auto;
  }
  .table_head {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background: rgba($dark-grey, .3);
    color: $grey;
    padding: 15px 20px;
    margin-bottom: 5px;
    height: 42px;
    word-break: break-word;
  }
  .table_head,
  .table_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .table_row {
    border-bottom: 1px solid hsla(0,0%,100%,.1);
    height: 49px;
  }
  .table_rowText {
    font-size: 14px;
    line-height: 16px;
    padding: 15px 20px;
    align-self: center;
  }
  @include r($xl) {
    margin-bottom: 40px;
  }
  @include r($md) {
    flex-direction: column;

    .table_column {
      padding-bottom: 15px;
    }
    .heading-col {
      display: none;
    }
    .table_body {
      .table_row {
        justify-content: space-between;
      }
    }
  }//$md
}//table_new
