@font-face {
  font-family:'vcr_osd_mono';
  src:url('../fonts/vcr_osd_mono-webfont.eot');
  src:url('../fonts/vcr_osd_mono-webfont.eot?#iefix')
    format('embedded-opentype'),
    url('../fonts/vcr_osd_mono-webfont.svg#vcr_osd_mono') format('svg'),
    url('../fonts/vcr_osd_mono-webfont.ttf') format('truetype'),
    url('../fonts/vcr_osd_mono-webfont.woff') format('woff'),
    url('../fonts/vcr_osd_mono-webfont.woff2') format('woff2');
  font-weight:bold;
  font-style:normal;
}

header {
    z-index: 1050;
}

.justify-content-center {
    justify-content: center;
}
.align-self-center {
    align-self: center;
}

.section {
  position:relative;
  padding-top:70px;
  padding-bottom:70px;
  display:flex;
  justify-content:center;
  align-items:center;
  color:$white;
  background-repeat:no-repeat;
  background-position:center top;
  background-size:cover;

  @include r($lg) {
    padding-top:55px;
    padding-bottom:60px;
  }

  @include r($md) {
    min-height:0;
  }

  @include r($xs) {
    padding-top:35px;
    padding-bottom:40px;
  }

  @include r($width_mob) {
    padding-top:23px;
  }
  &__logo {
    margin-bottom:42px;

    @include r($lg) {
      margin-bottom:27px;
    }

    @include r($xs) {
      margin-bottom:15px;
    }
    img {
      max-height:124px;
    }
  }
  &__subtitle {
    font-size:18px;
    line-height:26px;
    margin:0 auto 70px;
    text-align:center;
    max-width:650px;
    letter-spacing:0.5px;
    text-indent:0.5px;

    @media (max-width:1599px) {
      font-size:14px;
      line-height:18px;
      margin-bottom:40px;
      max-width:400px;
    }

    @include r($lg) {
      margin-bottom:35px;
      letter-spacing:0.085px;
    }

    @include r($xs) {
      margin-bottom:40px;
    }
  }
  &__platforms {
    margin-top:15px;
    font-size:12px;
    color:rgba(#fff, 0.6);
  }
  &__content {
    &--front {
      text-align:center;
      .promo__wrap {
        margin: 0 auto;
      }
      @include r($lg) {
        width:100%;
      }
    }
    .btn-primary {
      @include r($xs) {
        width:100%;
      }
    }
  }
  &__arrow {
    position:absolute;
    z-index:1;
    bottom:10px;
    left:50%;
    margin-left:-29px;
    display:block;
    width:58px;
    height:42px;
    //    opacity: .5;
    opacity:1;
    background-image:url(../images/content/about/arrow-scroll.svg);
    background-repeat:no-repeat;
    background-position:center center;
    background-size:100%;
    //    transform: translateX(-50%);
    animation:arrow 1.7s infinite ease;
    @include transition;

    @include r($lg) {
      bottom:20px;
      margin-left:-20px;
      width:32px;
      height:24px;
      background-size:100%;
    }

    @include r($md) {
      display:none;
    }
    &:hover {
      .html--desktop & {
        opacity:1;
      }
    }

    @keyframes arrow {
      0% {
        transform:translateY(0);
      }
      50% {
        transform:translateY(-7px);
      }
      55% {
        transform:translateY(-7px);
      }
      100% {
        transform:translateY(0);
      }
    }
  }
  &__lines {
    position:absolute;
    z-index:-1;
    top:0;
    display:flex;
    width:100%;
    height:100%;
    &--index-0 {
      z-index:0;
    }
  }
  &__line {
    margin-right:auto;
    margin-left:auto;
    width:1px;
    height:100%;
    background-color:#212324;
  }
  &--front {
    z-index:2;
    overflow:hidden;
    flex-direction:column;
    padding-top: 174px;

    @include r($lg) {
      padding-top:159px;
    }
    @include r($table-screen) {
        padding-top: 115px;
    }

    @include r($xs) {
      padding-top:95px;
    }

    @include r($width_mob) {
      padding-top:83px;
    }
    &-top {
      display:none;
      position:absolute;
      top:30px;
      left:0;
      .row {
        height:40px;
      }
      @include r($lg) {
        top:20px;
      }
    }
    .section__content--front {
      display:flex;
      flex-basis:100%;
      flex-grow:1;
      align-items:center;
      justify-content:center;
      padding-bottom:40px;
      @include r($md) {
        padding-bottom:20px;
      }
    }
    @include r($xl) {
      .btn-primary {
        font-size:14px;
      }
    }
    @include r($lg) {
      .btn-primary {
        font-size:13px;
        letter-spacing:0.5px;
        text-indent:0.5px;
      }
    }

    @include r($md) {
      html.html--device & {
        padding-top:110px;
        @media (orientation:portrait) {
          .col-sm-6 {
            flex:0 0 100%;
            max-width:100%;
          }
        }
      }
    }
    @media (max-width:959px) {
      html.html--device & {
        @media (orientation:landscape) {
          padding-top:80px;
        }
      }
    }

    @include r($width_mob) {
      html.html--device & {
        padding-top:70px;
      }
    }
    .section__subs {
      @include r($xs) {
        align-self:center;
      }
    }
    .section__logo,
    .section__title,
    .section__subs {
      visibility:hidden;
    }
  }
  &--features {
    z-index:1;
    padding-bottom:140px;
    background-color:$dark;
    @include media-breakpoint-down(xl) {
      padding-bottom:80px;
    }

    @include r($xs) {
      padding-bottom:45px;
      overflow:hidden;
    }
  }
  &--roles {
    @include r($xs) {
      html.html--device & {
        padding-top:65px;
      }
    }
  }
  &--modes {
    flex-direction:column;
  }
  &--subscribe {
    padding-bottom:135px;
    text-align:center;
    html.html--device & {
      padding-bottom:0px;
    }
    @include r($lg) {
      flex-wrap:wrap;
    }

    @include r($md) {
      padding-top:50px;
      padding-bottom:0;
      background-color:$dark;
      background-size:auto 70%;
      background-position:34% 0;
    }

    @include r($width_mob) {
      padding-top:60px;
      background-position:45% 0;
      background-size:333%;
    }
    .section__logo {
      margin-bottom:size-w(80, 1920);

      @include r($lg) {
        margin-bottom:50px;
      }

      @include r($xs) {
        margin-bottom:30px;
        padding-top:0;
      }
      @include r($width_mob) {
        margin-right:0;
      }
    }
    .section__subtitle {
      @include r($xs) {
        max-width:320px;
        margin-bottom:50px;
      }
      @include r($width_mob) {
        margin-bottom:40px;
      }
    }
  }
  &--slider {
    display:flex;
    align-items:center;
    @include r($xs) {
      padding-top:40px;
      padding-bottom:40px;
    }
  }
  &__top_text {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
}

.slick-dots-counts {
  margin:0;
  padding:0;
  list-style:none;
  font-size:10px;
  li {
    display:none;
  }
  .slick-active {
    display:block;
  }
  span {
    color:#8b9199;
  }
}
.slick-slide {
  outline:none !important;
}
.slick-arrow {
  position:absolute;
  z-index:11;
  top:50%;
  margin-top:-16px;
  padding:0;
  display:block;
  color:#9ea4ae;
  background-color:transparent;
  border:none;

  @include transition;
  &:hover {
    .html--desktop & {
      color:$primary;
    }
  }
  svg {
    display:block;
    width:12px;
    height:20px;
    overflow:visible;
    @include r($lg) {
      width:9px;
      height:15px;
    }
    @include r($width_mob) {
      width:10px;
      height:17px;
    }
  }
  &--prev {
    left:0;
  }
  &--next {
    right:0;
  }
  &.slick-disabled {
    opacity:0 !important;
    pointer-events:none;
  }
}
