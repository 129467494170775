.icons {
  margin-bottom:3vw;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;

  @include r($md) {
    margin-bottom: 30px;
  }
  @include r($width_mob) {
    margin-bottom:0;
    flex-wrap:wrap;
    align-items:flex-start;
    min-width:0;
  }
  &__item {
    width:(100%/3);

    @include r($width_mob) {
      margin-bottom:25px;
      width:50%;
    }
    &:nth-child(2) {
      .icons__img {
        border-left:1px solid rgba($grey, .25);
        border-right:1px solid rgba($grey, .25);
        @include r($width_mob) {
          border:0;
        }
      }
    }
  }
  &__img {
    margin-right:auto;
    margin-bottom:30px;
    margin-left:auto;
    display:flex;
    height:60px;
    justify-content:center;
    align-items:center;

    @include r($lg) {
      margin-bottom:20px;
      height:50px;
    }

    @include r($width_mob) {
      margin-bottom:10px;
    }
    img {
      max-height:100%;
    }
  }
  &__text {
    margin-right:auto;
    margin-left:auto;
    width:80%;
    text-align:center;
    text-transform:uppercase;
    word-break:break-word;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 0.4px;

    @include r($xl) {
      font-size: 14px;
      line-height: 1.86;
      letter-spacing: 0.35px;
    }

    @include r($lg) {
      width:85%;
    }
  }
}
