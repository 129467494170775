.modes {
  &__clouds {
    bottom:0;
    opacity:0;
    position:absolute;
    left:0;
    right:0;
    transition:opacity 0.25s ease-in-out;
    img {
      width:75%;
    }
  }
  &__head {
    position:relative;
    text-align: center;
    .mfp-wrap & {
      padding-top: 30px;
    }
  }
  &__container {
    position:relative;
    @media (max-width:1770px) {
      max-width:93%;
    }
  }
  &__content {
    display:flex;
    align-items:center;
    position:relative;
    width:100%;
    flex-basis:100%;
    flex-grow:1;
    flex-flow:0;
    &_aside {
      position:absolute;
      top:50%;
      transform:translate3d(0, -50%, 0);
      &_top {
        background:rgba(#fff, 0.4);
        position:absolute;
        top:0;
        right:12px;
        left:12px;
        height:1px;
        opacity:0;
        transform:translate3d(0, 20px, 0);
        transition:all 0.25s ease-in-out;
        &:before {
          content:'';
          width:8px;
          height:8px;
          position:absolute;
          top:0;
          left:-12px;
          border:2px solid #eab734;
          border-width:2px 0 0 2px;
        }
        &:after {
          content:'';
          width:8px;
          height:8px;
          position:absolute;
          top:0;
          right:-12px;
          border:2px solid #eab734;
          border-width:2px 2px 0 0;
        }
      }
      &_bottom {
        background:rgba(#fff, 0.4);
        position:absolute;
        bottom:0;
        right:12px;
        left:12px;
        height:1px;
        opacity:0;
        transform:translate3d(0, -20px, 0);
        transition:all 0.25s ease-in-out;
        text-align:center;
        &:before {
          content:'';
          width:8px;
          height:8px;
          position:absolute;
          bottom:0;
          left:-12px;
          border:2px solid #eab734;
          border-width:0 0 2px 2px;
        }
        &:after {
          content:'';
          width:8px;
          height:8px;
          position:absolute;
          bottom:0;
          right:-12px;
          border:2px solid #eab734;
          border-width:0 2px 2px 0;
        }
      }
      svg {
        margin:0 auto;
        position:relative;
        overflow:visible;
        width:100%;
        text {
          font-family:'Caliber';
          font-size:280px;
          font-weight:bold;
          fill:none;
          stroke-width:2;
          stroke:#fefefe;
          letter-spacing:-25px;
        }
        rect {
          fill:#fefefe;
          transform:translate(-65%, -100%) rotate(-10deg);
        }
      }
    }
    &_video {
      position:relative;
      padding:30px;
      .html--device & {
        visibility:visible;
      }
      @media (max-width:1699px) {
        flex:0 45%;
        max-width:45%;
      }
      @include r($lg) {
        flex:0 52.5%;
        max-width:52.5%;
        padding:20px;
      }
      &_top {
        background:rgba(#fff, 0.4);
        position:absolute;
        top:0;
        right:12px;
        left:12px;
        height:1px;
        &:before {
          content:'';
          width:8px;
          height:8px;
          position:absolute;
          top:0;
          left:-12px;
          border:2px solid #eab734;
          border-width:2px 0 0 2px;
        }
        &:after {
          content:'';
          width:8px;
          height:8px;
          position:absolute;
          top:0;
          right:-12px;
          border:2px solid #eab734;
          border-width:2px 2px 0 0;
        }
      }
      &_bottom {
        background:rgba(#fff, 0.4);
        position:absolute;
        bottom:0;
        right:12px;
        left:12px;
        height:1px;
        text-align:center;
        &:before {
          content:'';
          width:8px;
          height:8px;
          position:absolute;
          bottom:0;
          left:-12px;
          border:2px solid #eab734;
          border-width:0 0 2px 2px;
        }
        &:after {
          content:'';
          width:8px;
          height:8px;
          position:absolute;
          bottom:0;
          right:-12px;
          border:2px solid #eab734;
          border-width:0 2px 2px 0;
        }
      }
      &_middle {
        position:relative;
        overflow:hidden;
        &_link {
          display:block;
          position:relative;
          z-index:3;
          &_play {
            background:#fefefe;
            border-radius:50%;
            position:absolute;
            top:50%;
            left:50%;
            margin:-41px 0 0 -41px;
            height:82px;
            width:82px;
            transition:all 0.25s ease-in-out;
            path {
              fill:#181819;
            }
            @media (max-width:1200px) {
              margin:-33px 0 0 -33px;
              height:66px;
              width:66px;
            }
            @include r($width_mob) {
              margin:-24px 0 0 -24px;
              height:48px;
              width:48px;
            }
          }
          &:hover {
            .html--desktop & {
              .modes__content_video_middle_link_play {
                background:#eab734;
              }
            }
          }
          &.playing {
            pointer-events:none;
            z-index:1;
            .modes__content_video_middle_poster,
            .modes__content_video_middle_link_play {
              opacity:0;
              pointer-events:none;
            }
          }
        }
        &_poster {
          position:relative;
          display:block;
          width:100%;
          transition:opacity 0.25s ease-in-out;
        }
        &_youtube {
          position:absolute;
          left:0;
          top:0;
          height:100% !important;
          width:100% !important;
          z-index:2;
        }
      }
    }
    &_left {
      padding:51px 34px;
      left:0;
      @include r($lg) {
        padding:42px 26px;
      }
      .html--device & {
        visibility:visible;
      }
      &_pvp {
        display:none;
      }
    }
    &_right {
      padding:51px 42px;
      right:0;
      .html--device & {
        visibility:visible;
      }
      &_pve {
        display:none;
      }
    }
    &_more {
      margin:0 auto;
      opacity:0;
      pointer-events:none;
      transition:all 0.25s ease-in-out;
      transition-delay:0s;
      position:relative;
      margin-top:-25px;
      position:relative;
      transform:scaleX(0);
      @include r($xl) {
          margin-top: -21px;
      }
    }
    &_center {
      max-width:75%;
      position:relative;
      pointer-events:none;
      img {
        display:block;
        max-height:33vw;
        transition:opacity 0.25s ease-in-out;
      }
      &_pvp {
        opacity:0;
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
      }
      &_pve {
        opacity:0;
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
      }
    }
  }
  &__img_left {
    position:absolute;
    left:0;
    bottom:0;
    width:40%;
    img {
      display:block;
      max-width:100%;
      max-height:40vw;
    }
  }
  &__img_right {
    position:absolute;
    right:0;
    bottom:0;
    width:40%;
    img {
      display:block;
      margin-left:auto;
      max-width:100%;
      max-height:40vw;
    }
  }
  &__close {
    border: solid 1px rgba($dark-grey, .6);
    background-color: rgba($dark, .6);
    display:block;
    position:fixed;
    right:30px;
    top:100px;
    height:60px;
    width:60px;
    transition:all $anim ease-in-out;
    z-index:1045;
    path {
      stroke:$white;
      stroke-width:2;
      transition:stroke $anim ease-in-out;
    }
    &:hover {
      .html--desktop & {
        background:$color-main;
        border-color: $color-main;
        path {
          stroke:$black;
        }
      }
    }
    @include r($lg) {
      right:20px;
      top:80px;
    }
    @include r($md) {
      width:40px;
      height:40px;
    }
  }
}
.modes.pvp {
  .modes__clouds {
    transform:scaleX(1);
    opacity:1;
  }
  .modes__content {
    .modes__content_left {
      .modes__content_aside_top,
      .modes__content_aside_bottom {
        opacity:1;
        transform:translate3d(0, 0, 0);
      }
      .modes__content_more {
        opacity:1;
        transition-delay:0.25s;
        pointer-events:all;
        transform:scaleX(1);
      }
      svg {
        rect {
          animation:scaleFont 0.35s forwards;
        }
      }
    }
    .modes__content_center {
      .modes__content_center_default {
        opacity:0;
      }
      .modes__content_center_pvp {
        opacity:1;
      }
    }
  }
}
.modes.pve {
  .modes__clouds {
    transform:scaleX(-1);
    opacity:1;
  }
  .modes__content {
    .modes__content_right {
      .modes__content_aside_top,
      .modes__content_aside_bottom {
        opacity:1;
        transform:translate3d(0, 0, 0);
      }
      .modes__content_more {
        opacity:1;
        transition-delay:0.25s;
        pointer-events:all;
        transform:scaleX(1);
      }
      svg {
        rect {
          animation:scaleFont 0.35s forwards;
        }
      }
    }
    .modes__content_center {
      .modes__content_center_default {
        opacity:0;
      }
      .modes__content_center_pve {
        opacity:1;
      }
    }
  }
}

@keyframes scaleFont {
  from {
    transform:translate(-65%, -100%) rotate(-10deg);
  }
  to {
    transform:translate(10%, 10%) rotate(-10deg);
  }
}
html.html--device {
  .modes {
    padding-bottom:75px;
    .mfp-wrap & {
      padding-top:60px;
    }
    @media (max-width:667px) and (orientation:landscape) {
      .mfp-wrap & {
        padding-top:25px;
        padding-bottom:20px;
      }
    }
    @include r($width_mob) {
      .mfp-wrap & {
        padding-top:25px;
        padding-bottom:20px;
      }
    }
    &__container {
      max-width:100%;
    }
    &__content {
      padding-top:50px;
      @media (max-width:600px) {
        padding-top:80px;
        flex-direction:column;
        justify-content:center;
      }
      &_aside {
        position:relative;
        top:auto;
        transform:translate3d(0, 0, 0);
        flex:0 50%;
        max-width:50%;
        padding:0 10px;
        @media (max-width:600px) {
          flex:0 100%;
          max-width:100%;
        }
        &_content {
          position:relative;
          max-width:66.6667%;
          margin:-10% auto 0;
          padding:51px 34px;
          @media (max-width:800px) {
            padding:32px 30px 45px;
          }
          @media (max-width:600px) {
            width:320px;
            max-width:100%;
            margin:0 auto 0;
          }
        }
        &_top,
        &_bottom {
          opacity:1;
          transform:translate3d(0, 0, 0);
        }
        svg {
          rect {
            transform:translate(10%, 10%) rotate(-10deg);
          }
        }
      }
      &_left {
        &_pvp {
          display:block;
          transform:scale(1.25);
          max-width: 100%;
          @media (max-width:600px) {
            margin:0 auto;
            width:480px;
            max-width:100%;
            transform:scale(1.66);
          }
        }
      }
      &_right {
        @media (max-width:600px) {
          margin-top:130px;
        }
        &_pve {
          display:block;
          transform:scale(1.25);
          max-width: 100%;
          @media (max-width:600px) {
            margin:0 auto;
            width:480px;
            max-width:100%;
            transform:scale(1.66);
          }
        }
      }
      &_center {
        display:none;
      }
      &_more {
        opacity:1;
        transition-delay:0.25s;
        pointer-events:all;
        transform:scaleX(1);
      }
    }
    &__close {
      @include r($md) {
        top:80px;
      }
      @include r($width_mob) {
        top:60px;
        right:0;
      }
    }
    @media (max-width:959px) {
      @media (orientation:portrait) {
        &__img_left,
        &__img_right {
          display:none;
        }
        &__content {
          &_video {
            padding:0 10px;
            flex:0 100%;
            max-width:100%;
            &_top,
            &_bottom {
              display:none;
            }
          }
        }
      }
    }
    @media (max-width:667px) {
      &__img_left,
      &__img_right {
        display:none;
      }
      &__content {
        &_video {
          padding:0 10px;
          flex:0 100%;
          max-width:100%;
          &_top,
          &_bottom {
            display:none;
          }
        }
      }
    }
    @include r($width_mob) {
      &__container_popup {
        padding:0;
      }
    }
  }
  .mfp-wrap {
    .modes {
      padding-top: 67px;
      @include r($lg) {
        padding-top: 60px;
        &__head {
          padding-left: 80px;
          padding-right: 80px;
        }
      }
      @include r($width_mob) {
        &__head {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .modes.popup {
    .modes__content {
      @media (max-width:600px) {
        padding-top:0;
        padding-bottom: 0;
        @media (orientation:landscape) {
          padding-top:50px;
          padding-bottom: 0;
        }
      }
      @media (max-width:374px) {
        padding-top:30px;
      }
    }
  }
}

.modes.popup {
  padding-bottom: 30px;
  justify-content: space-evenly;
  .modes__head {
    .row {
      align-items: center;
      flex-direction: column;
      padding-left: 30px;
      padding-right: 30px;
    }
    @include r($lg) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include r($md) {
      .col-6 {
        flex: 0 0 auto;
        max-width: 100%;
      }
    }
    @include r($width_mob) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .modes__content {
    flex: 0 1 auto;
  }
}
