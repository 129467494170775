.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  cursor: pointer;
  border: none;
  background-color: transparent;
  max-width:100%;
  transition: all $anim ease;
}

.btn-default {
  display: inline-block;
  padding: 15px 40px;
  font-size: 16px;
  letter-spacing: 0.57;
  line-height: 19px;
  color: $main-black;
  background: $color-main;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  transition: all $anim ease;
  min-width: 190px;
  @include r($xl) {
    min-width: 160px;
    font-size: 14px;
    padding: 12px 30px;
  }
  @include r($lg) {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 17px;
  }
  .desktop &:hover {
    color: $main-black;
    background: $hover-color;
  }
}

.btn-secondary {
  box-sizing: border-box;
  display: inline-block;
  padding: 14px 39px;
  font-size: 16px;
  letter-spacing: 0.57;
  line-height: 19px;
  border: 1px solid rgba($grey, .5);
  color: $white;
  background: transparent;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  transition: all $anim ease;
  min-width: 190px;
  @include r($xl) {
    min-width: 160px;
    font-size: 14px;
    padding: 11px 29px;
  }
  @include r($lg) {
    padding-left: 19px;
    padding-right: 19px;
    line-height: 17px;
  }
  .desktop &:hover {
    background: $color-main;
    border-color: $color-main;
    color: $main-black;
  }
}

.btn-wide {
  display: flex;
  width: 100%;
  padding: 30px 40px;
  font-size: 28px;
  letter-spacing: 0.7px;
  line-height: 0.93;
  color: $main-black;
  background: $color-main;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;

  .desktop &:hover {
    color: $main-black;
    background: $hover-color;
  }
  @include r($xl) {
    font-size: 24px;
    letter-spacing: 0.6px;
    line-height: 1.08;
    padding: 25px 35px;
  }
  @include r($md) {
    font-size: 20px;
    letter-spacing: 0.5px;
    padding: 20px 25px;
  }
  @include r($sm) {
    font-size: 14px;
    letter-spacing: 0.5px;
    padding: 17px 15px;
  }
}

.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  .small-icon {
    margin-right: 20px;
    img {
      display: block;
      max-height: 30px;
      width: auto;
      max-width: none;
    }
  }
  @include r($md) {
    .small-icon {
      margin-right: 15px;
      img {
        max-height: 24px;
      }
    }
  }
  @include r($sm) {
    .small-icon {
      img {
        max-height: 20px;
      }
    }
  }
}
